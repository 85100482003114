import React, { useEffect, useState } from "react"

interface BannerHeadingProps {
  viewPortValue: string
  color: string
  title: string
  onlyForDisplay?: boolean
}

const BannerHeading = ({ color, title, viewPortValue, onlyForDisplay = false }: BannerHeadingProps) => {
  const [fontSize, setFontSize] = useState("text-2xl")

  useEffect(() => {
    if (viewPortValue === "mobileThumbnail") {
      setFontSize("text-2xl")
    } else {
      if (viewPortValue === "tabletThumbnail") {
        setFontSize("text-2xl")
      }
    }
  }, [viewPortValue])

  if (onlyForDisplay === true) {
    return <h1
      className={"text-5xl font-semibold text-xs w-4/5  md:text-sm md:w-3/4 lg:text-xl lg:w-2/3  xl:text-3xl xl:w-5/6"}>{title}

    </h1>
  } else {
    return <h1
      className={`font-semibold ${fontSize} leading-snug font-poppins`}
      style={{ color: color }}
    >
      {title}
    </h1>
  }
}
  export default BannerHeading
