import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../core/components/Button'
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline'
import KeywordPill from './KeywordPill'
import toast from 'react-hot-toast'
import { getKeywordsFromUrl, slugifyWord } from '../../../data/stores.data'

interface subKeyword {
  keyword: string;
  isActive: boolean;
}

interface keyword {
  keyword: string;
  isActive: boolean;
  count: number;
  groupedKeywords: subKeyword[];
}


interface keywordsProps {
  keywordData: {
    url: string;
    slug: string;
  }
  alreadySelectedKeywords?: keyword[];
  exportSelectedKeywordsArray: (keywords: string[]) => void,
  submittable: boolean;
  loading?: boolean;
  exportFetchedKeywords?: (keywords: any[]) => void;
}

const Keywords = ({
                    keywordData,
                    exportSelectedKeywordsArray,
                    alreadySelectedKeywords,
                    submittable,
                    loading,
                    exportFetchedKeywords,
                  }: keywordsProps) => {


  const [alreadySelectedKeywordsState, setAlreadySelectedKeywordsState] = useState(alreadySelectedKeywords)
  useEffect(() => {
    setAlreadySelectedKeywordsState(alreadySelectedKeywords)
  }, [alreadySelectedKeywords])

  interface resKeyword {
    keywords: string[];
    mainKeyword: string
  }

  interface subKeyword {
    keyword: string;
    isActive: boolean;
  }

  interface keyword {
    keyword: string;
    isActive: boolean;
    count: number;
    groupedKeywords: subKeyword[];
  }

  const [intensity, setIntensity] = useState<number>(0)
  const [keywordsArray, setKeywordsArray] = useState<keyword[]>([])
  const [keywordsLoading, setKeywordsLoading] = useState<boolean>(false)
  const [keywordWindowSizeIncrease, setKeywordWindowSizeIncrease] = useState<boolean>(false)
  const scrapeKeywordsFromUrl = async () => {
    if (!(keywordData.url && keywordData.slug)) {
      if (!keywordData.url) {
        toast.error('Please fill Store URL and try again')
      }
      if (!keywordData.slug) {
        toast.error('Please fill Store slug and try again')
      }
      return
    }

    setKeywordsLoading(true)


    if (intensity === 0) {
      await keywordPopulateHandler(keywordData.slug, keywordData.url, false)

    }
    // for more intense keyword scraping (takes longer)
    else if (intensity >= 1) {
      await keywordPopulateHandler(keywordData.slug, keywordData.url, true)
    }

    setSubmitState(true)
    setKeywordsLoading(false)
    setIntensity(intensity + 1)
  }


  const keywordPopulateHandler = async (slug: string, url: string, fullSearch: boolean) => {
    await getKeywordsFromUrl(slug, url, fullSearch)
      .then(async (res) => {
        if (exportFetchedKeywords) {
          exportFetchedKeywords(res.keywords)
        }
        await setKeywordsArray([])
        await setAlreadySelectedKeywordsState(alreadySelectedKeywords)
        return await setStringKeywordArrayToKeywordArray(res)
      })
      .then(async (res) => {
        await setKeywordArrayHandler(res)
        setKeywordsLoading(false)

      })
      .catch((err) => {
        console.log(err)
        setKeywordsLoading(false)
      })

  }

  const setStringKeywordArrayToKeywordArray = async (groupedKeywordArray: { keywords: any }) => {
    if (alreadySelectedKeywordsState) {
      const filteredGroupedKeywordArray =
        groupedKeywordArray.keywords.map((keywordArray: string[]) => {
          return keywordArray.filter((keyword: string) => {
            return !alreadySelectedKeywordsState.find((selectedKeyword: keyword) => {
              return slugifyWord(selectedKeyword.keyword.toLowerCase()) === slugifyWord(keyword.toString().toLowerCase())
            })
          })
        })

      return await filteredGroupedKeywordArray.map((group: string[]) => {
        return {
          mainKeyword: slugifyWord(group.sort((a, b) => a.length - b.length)[0], undefined, undefined, ' '),
          keywords: group.map((keyword) => slugifyWord(keyword, undefined, undefined, ' ')),
        }
      })

    } else {
      return await groupedKeywordArray.keywords.map((group: string[]) => {
        return {
          mainKeyword: slugifyWord(group.sort((a, b) => a.length - b.length)[0], undefined, undefined, ' '),
          keywords: group.map((keyword) => slugifyWord(keyword, undefined, undefined, ' ')),
        }
      })
    }
  }


  const setKeywordArrayHandler = async (keywords: resKeyword[]) => {
    const filteredKeywords = keywords.filter((keyword) => {
      return keyword.mainKeyword !== undefined
    })

    setKeywordsArray(filteredKeywords.map((item: resKeyword) => {
      return {
        keyword: item.mainKeyword.toLowerCase(),
        isActive: false,
        count: item.keywords.length === 1 ? 1 : item.keywords.length - 1,
        groupedKeywords: item.keywords
          .filter((subItem: string) =>
            subItem.toLowerCase() !== item.mainKeyword.toLowerCase(),
          )
          .map((subItem: any) => {
            return {
              keyword: subItem.toLowerCase(),
              isActive: false,
            }
          }),
      }
    }))
  }


  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([])
  const handleKeywordSelection = (keyword: keyword) => {
    if (keyword.isActive) {
      const index = selectedKeywords.findIndex((item) => item === keyword.keyword)
      if (index > -1) {
        selectedKeywords.splice(index, 1)
      }
      setSelectedKeywords([...selectedKeywords])
      setKeywordsArray(keywordsArray.map((item) => {
        if (item.keyword === keyword.keyword) {
          return {
            ...item,
            isActive: false,
          }
        }
        return item
      }))
    } else {
      setSelectedKeywords([...selectedKeywords, keyword.keyword])
      setKeywordsArray(keywordsArray.map((item) => {
        if (item.keyword === keyword.keyword) {
          return {
            ...item,
            isActive: true,
          }
        }
        return item
      }))
    }
  }

  const handleSubKeywordClick = (keyword: keyword, subKeyword: string) => {
    setSelectedKeywords((prev) => {
      if (prev.includes(subKeyword)) {
        return prev.filter((item) => item !== subKeyword)
      } else {
        return [...prev, subKeyword]
      }
    })

    setKeywordsArray(keywordsArray.map((item) => {
      if (item.keyword === keyword.keyword) {
        return {
          ...item,
          groupedKeywords: item.groupedKeywords.map((subItem) => {
            if (subItem.keyword === subKeyword) {
              return {
                ...subItem,
                isActive: !subItem.isActive,
              }
            }
            return subItem
          }),
        }
      }
      return item
    }))
  }

  useEffect(() => {
    if (!submittable) {
      exportSelectedKeywordsArray(selectedKeywords)
    }
  }, [selectedKeywords])


  const handleNewKeywordEdit = async () => {
    setSubmitState(true)
    await setAlreadySelectedKeywordsState(alreadySelectedKeywords)
    await setKeywordsArray([])
    await setSelectedKeywords([])


    await exportSelectedKeywordsArray(selectedKeywords)

    if (keywordsArray.length === 0) {
      setSubmitState(false)
    }

    setSubmitState(false)
    setKeywordWindowSizeIncrease(false)
  }


  const [submitState, setSubmitState] = useState<boolean>(loading || false)

  useEffect(() => {
    if ((loading || !keywordsLoading) && !submittable) {
      if (keywordsArray.length === 0 && intensity > 0) {
        setSubmitState(false)
        toast.error('No keywords found on this page')
      }
    }
  }, [keywordsArray, intensity])

  return (
    <div className='mt-1 sm:mt-0 sm:col-span-2 grid sm:max-w-lg gap-2'>
      <div>
        {!submitState &&
          <PrimaryButton
            disabled={keywordsLoading}
            loading={keywordsLoading}
            type='button'
            title={'Fetch Keywords'}
            onClick={scrapeKeywordsFromUrl}
          />
        }
      </div>
      {!loading &&
        <div className={`overflow-y-auto mb-5 ${keywordWindowSizeIncrease ? 'h-full' : 'h-32'}`}>
          {keywordsArray.length > 0 ?
            <div className='flex flex-wrap w-full pt-2 pb-4'>
              {keywordsArray.map((item: keyword) => {
                return (
                  <KeywordPill
                    keyword={item}
                    key={item.keyword}
                    onClickHandler={handleKeywordSelection}
                    setSelectedSubKeywords={handleSubKeywordClick} />
                )
              })}
            </div>
            :
            <p className='mt-2 text-sm text-gray-500'>
              Still no fetched keywords.
            </p>
          }
        </div>
      }
      <div>
        {submittable && submitState && (
          <PrimaryButton
            disabled={keywordsLoading || loading}
            loading={loading}
            type='button'
            title={loading ? 'Submitting...' : 'Submit Keywords'}
            onClick={handleNewKeywordEdit}
          />
        )}
        {keywordsArray.length > 0 && (
          <button
            type='button'
            onClick={() => setKeywordWindowSizeIncrease(!keywordWindowSizeIncrease)}
            className=' inline-flex items-center px-4 py-2 rounded-md text-xs font-medium bg-gray-200 text-gray-800 float-right '
          >
            {keywordWindowSizeIncrease ?
              (<SortAscendingIcon className={`h-6 w-6 relative top-1`} />) :
              (<SortDescendingIcon className={`h-6 w-6 relative top-1`} />)}
          </button>
        )}
      </div>
    </div>
  )
}

export default Keywords
