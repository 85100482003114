/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import withProtected from "../../../core/hoc/withProtected"

import { useNavigate, useParams } from "react-router-dom"
import { Location, Store } from "../../../types"
import { getStore } from "../../../data/stores.data"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import AddNewLocationForm from "../components/AddNewLocationForm"
import EditLocationForm from "../components/EditLocationForm"
import { AddLocationsStore, getLocations } from "../../../data/locations_data"
import toast from "react-hot-toast"

const EditLocations = () => {
  const navigate = useNavigate()

  const { slug, id } = useParams<{ slug: string; id: string }>();
  const [trigger, setTrigger] = useState<boolean>(false)
  const [store, setStore] = useState<Store | null>(null)

  const [showAddNewLocationForm, setShowAddNewLocationForm] = useState(true)
  const [locations, setLocations] = useState<Location[]>([]);
  const [editingLocation, setEditingLocation] = useState<Location>(locations[parseInt(id!, 10)])

  const fetchStore = async (slug: string) => {
    getStore(slug!)
      .then(async (store) => {
        setStore(store!)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchStore(slug!).then()
  }, [slug, trigger])


  const fetchLocationsFromApi = async () => {
    try {
      const storeLocations: Location[] = await getLocations(slug!);
      setLocations(storeLocations);

      const locationToEdit = storeLocations.find(location => location.id === parseInt(id!, 10));
      if (locationToEdit) {
        setEditingLocation(locationToEdit);
      }
    } catch (error) {
      console.error('Failed to fetch locations:', error);
    }
  };

  useEffect(() => {
    fetchLocationsFromApi();
  }, []);

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: store?.name!,
                route: `/stores/edit/${store?.slug}/locations`,
              },
              {
                name: "Edit Location",
                route: `/stores/edit/${store?.slug}/locations/edit/${id}`,
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex-auto sm:pb-6 sm:border-b-2 sm:border-ephemeral-mist">
            <h1 className="text-xl font-semibold text-gray-900">Edit Location</h1>
            <p className="mt-2 text-sm text-gray-700">
              You can edit location here
            </p>
          </div>
          <div className="flex flex-col mt-8">
            <EditLocationForm  setShowAddNewLocationForm={setShowAddNewLocationForm} showAddNewLocationForm={showAddNewLocationForm}
                                className={'bg-transparent'} isSeparateLocationForm={true}
                                onCancel={()=> navigate(`/stores/edit/${store?.slug}/locations`)}
             editingLocation={editingLocation}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withProtected(EditLocations)