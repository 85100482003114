import { ExclamationCircleIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { useForm, UseFormSetValue } from "react-hook-form"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import { Breadcrumb } from "../../../core/components/Breadcrumb"

import PrimaryButton from "../../../core/components/Button"
import { createCategory } from "../../../data/categories.data"
import withProtected from "../../../core/hoc/withProtected"

interface FieldValues {
  name: string;
  cover: string;
  slug: string;
  description: string;
  createdAt: string;
}

const AddCategory = () => {
  const [name, setName] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FieldValues>()

  // watch name for default slug
  const nameWatch = watch("name")

  // submit new store
  const onSubmit = async (data: FieldValues) => {
    // upload cover & logo

    setLoading(true)
    try {
      const body = {
        name: data.name,
        slug: data.slug,
        description: data.description ?? "",
      }

      const response = await createCategory(body)

      setLoading(false)
      if (response.data.success) {
        toast.success(response.data.message)
        navigate("/categories")
      } else {
        toast.error(response.data.message ?? "Error occured")
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message ?? "Error occured")
    }
  }

  useEffect(() => {
    setName(nameWatch)
  }, [nameWatch])


  interface GenerateSlugBtnsProps {
    name: string;
    setValue: UseFormSetValue<FieldValues>;
  }

  const generateNoSpacesSlug = (name: string): string => {
    const sanitizedName = name.toLowerCase().replace(/&/g, "and").trim()
    return sanitizedName.replace(/[^a-z0-9]/g, "").toLowerCase()
  }

  const generateHyphenatedSlug = (name: string): string => {
    const sanitizedName = name.toLowerCase().replace(/&/g, "and").trim()
    const slug = sanitizedName
      .replace(/[^a-z0-9\s]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
    return slug.replace(/^-|-$/g, "")
  }

  const generateSlugBtns = ({ name, setValue }: GenerateSlugBtnsProps) => {
    const noSpacesSlug = generateNoSpacesSlug(name)
    const hyphenatedSlug = generateHyphenatedSlug(name)

    return (
      <div className="mt-3">
        <div className="flex space-x-2">
          <span className="text-sm relative top-1">Suggestion: </span>
          <button
            type="button"
            onClick={() => setValue("slug", noSpacesSlug)}
            className="px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md"
          >
            {noSpacesSlug}
          </button>
          {name.includes(" ") && (
            <button
              type="button"
              onClick={() => setValue("slug", hyphenatedSlug)}
              className="px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md"
            >
              {hyphenatedSlug}
            </button>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (nameWatch) {
      const hyphenatedSlug = generateHyphenatedSlug(nameWatch)
      setValue("slug", hyphenatedSlug)
    } else {
      setValue("slug", "")
    }
  }, [nameWatch])


  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Categories",
                route: "/categories",
              },
              {
                name: "Add Category",
                route: "/categories/add",
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Add Category
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                You can add category here
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                  <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                    <div
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="username"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Category Name
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="relative flex max-w-lg rounded-md shadow-sm">
                          <input
                            disabled={loading}
                            type="text"
                            {...register("name", {
                              required: "Name cannot be empty",
                            })}
                            autoComplete="name"
                            className={classNames(
                              errors && errors.name
                                ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                : "focus:ring-primary-teal focus:border-primary-teal",
                              "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                            )}
                          />
                          {errors && errors.name && (
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <ExclamationCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>

                        {errors && errors.name && (
                          <div className="mt-2 text-sm text-red-600">
                            {errors.name.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="slug"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Slug
                        <span className={`text-xs text-gray-400 pl-2`}>
                            (Disabled Type, Auto Generated)
                          </span>
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="relative flex max-w-lg rounded-md shadow-sm">
                          <input
                            disabled={true}
                            type="text"
                            {...register("slug", {
                              required: "Slug cannot be empty",
                            })}
                            autoComplete="slug"
                            className={classNames(
                              errors && errors.slug
                                ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                                : "focus:ring-primary-teal focus:border-primary-teal",
                              "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                            )}
                          />

                          {errors && errors.slug && (
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                              <ExclamationCircleIcon
                                className="w-5 h-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {errors && errors.slug && (
                          <div className="mt-2 text-sm text-red-600">
                            {errors.slug.message}
                          </div>
                        )}
                        <div className="mt-3">
                          {name && generateHyphenatedSlug(name) && <>{generateSlugBtns({ name, setValue })}</>}
                        </div>
                      </div>
                    </div>

                    {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Cover Image
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <CoverImageDropZone
                          onDrop={(file) => {
                            setCover(file);
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Description (optional)
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      disabled={loading}
                      {...register("description")}
                      id="description"
                      name="description"
                      rows={3}
                      className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Write a few sentences about store.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end space-x-3">
                  <button
                    disabled={loading}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    disabled={loading}
                    loading={loading}
                    type="submit"
                    title={"Submit"}
                  ></PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withProtected(AddCategory)
