import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../types"

interface StoreListState {
  stores: Store[],
  startAndEndIds: number[]
  loading: boolean;
  error: string | null;
}

const initialState: StoreListState = {
  stores: [],
  startAndEndIds: [],
  loading: false,
  error: null,
}

const storeListSlice = createSlice({
  name: "storeList",
  initialState,
  reducers: {
    setStoreList: (state, action) => {
      const { stores, startId, endId } = action.payload

      if (stores && startId !== undefined && endId !== undefined) {
        // Check if both startId and endId exist in startAndEndIds
        const startIdExists = state.startAndEndIds.includes(startId)
        const endIdExists = state.startAndEndIds.includes(endId)

        if (!startIdExists && !endIdExists) {
          state.stores = [...state.stores, ...stores].sort((a, b) => a.index - b.index)

          state.startAndEndIds.push(startId, endId)
        }
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const { setStoreList, setLoading, setError } = storeListSlice.actions

export default storeListSlice.reducer