/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from "@heroicons/react/outline"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  getStats,
} from "../../../data/stores.data"
import { Store } from "../../../types"
import PrimaryButton from "../../../core/components/Button"
import StoreListItem from "../components/StoreListItem"
import withProtected from "../../../core/hoc/withProtected"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import { searchStores } from "../../../data/search_data"
import ResultsInfo from "../components/ResultsInfo"
import Pagination from "../components/Paginate"
import { storeListData } from "../../../data/store_list_data"
import { useAppDispatch, useAppSelector } from "../../../features/hooks/redux"
import { setStoreList, setLoading, setError } from "../../../features/storeListSlice"

const StoresPage = () => {
  const [stores, setStores] = useState<Store[]>([])

  const [searchText, setSearchText] = useState<string>("")

  const [currentPage, setCurrentPage] = useState(1)
  const [totalNoOfStores, setTotalNoOfStores] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const ITEMS_PER_PAGE = 15

  const storedStores = useAppSelector((state) => state.storeList.stores)
  const storedStartAndEndIds = useAppSelector((state) => state.storeList.startAndEndIds)
  const dispatch = useAppDispatch()

  const getTotalStoreCount = async () => {
    const totalStores = await getStats()

    setTotalNoOfStores(totalStores?.data?.storesCount!)

    const noOfPages = Math.ceil(totalStores?.data?.storesCount! / ITEMS_PER_PAGE)

    setPageCount(noOfPages)
  }

  const getStoresFromApi = async (pageNo: number) => {
    const startId = ITEMS_PER_PAGE * (pageNo - 1) + 1
    const endId = ITEMS_PER_PAGE * pageNo

    const isIdsAlreadyStored = storedStartAndEndIds.includes(startId) || storedStartAndEndIds.includes(endId)

    // Check if the required range is already in the store
    if (isIdsAlreadyStored) {
      setStores(storedStores.filter((store: Store) => store.index >= startId && store.index <= endId))
    } else {
      const fetchedStores = await storeListData(pageNo, ITEMS_PER_PAGE)

      if (fetchedStores.data) {
        dispatch(setStoreList({
          stores: fetchedStores.data.storeReults,
          startId: fetchedStores.data.metaData.startID,
          endId: fetchedStores.data.metaData.endID,
        }))

        setStores(fetchedStores.data.storeReults)
      }
    }

  }

  useEffect(() => {
    getTotalStoreCount()
  }, [])

  useEffect(() => {
    getStoresFromApi(currentPage)
  }, [currentPage])


  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setSearchText(text)
    const searchQuery = text.toLowerCase()

    if (searchQuery.length > 0) {
      const filteredStores = await searchStores(searchQuery, 16)
      setStores(filteredStores.data!)
    } else {
      getStoresFromApi(1)
    }
  }

  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected + 1)
  }

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Stores",
                route: "/stores",
                asLink: false,
              },
            ]}
          />
          <div className="lg:flex lg:items-center">
            <div className="lg:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Stores</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the stores in Mintpay
              </p>
            </div>
            <div className="mt-4 lg:mt-0 lg:ml-16 lg:flex-none">
              <Link to={"/stores/add"}>
                <PrimaryButton
                  title="Add Store"
                  icon={<PlusIcon className="w-5 h-5" />}
                />
              </Link>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="w-full lg:w-1/2 xl:w-1/3 mt-5">
              <div>
                <div className="relative flex items-center mt-1">
                  <input
                    onChange={handleSearch}
                    value={searchText}
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Search with store name"
                    className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                  />
                  <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                    <kbd
                      className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
                      ⌘K
                    </kbd>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="flex pb-4 ">
              <div className="hidden sm:block">
                {searchText ? (
                  <p className="text-sm text-gray-500">
                    <span className="font-medium">
                      {stores?.length}
                    </span>{" "}
                    {stores?.length === 1 ? "Result for" : "Results for"}
                    <span className="font-medium">"{searchText}"</span>
                  </p>
                ) : (
                  <p className="text-sm text-gray-500">&nbsp;</p>
                )}
              </div>
            </div>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="w-full min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Categories
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {stores && stores.length === 0 && (
                      <tr>
                        <td
                          colSpan={4}
                          className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6"
                        >
                            <span className="text-gray-500">
                              No matched results for the query
                            </span>
                        </td>
                      </tr>
                    )}
                    {stores?.map((store) => (
                      <StoreListItem key={store.slug} store={store} />
                    ))}
                    </tbody>
                  </table>
                </div>
                {!searchText && <div className={` flex place-content-between items-center  py-5 `}>
                  <ResultsInfo
                    currentPage={currentPage}
                    pageSize={ITEMS_PER_PAGE}
                    totalResults={totalNoOfStores}
                  />
                  <Pagination
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                  />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withProtected(StoresPage)
