import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import Skeleton from "react-loading-skeleton"
import { Link, useParams } from "react-router-dom"
import { getStore } from "../../../data/stores.data"
import { Category, Store } from "../../../types"
import dayjs from "dayjs"
import { PencilAltIcon } from "@heroicons/react/outline"
import { getCategoriesByStore } from "../../../data/categories.data"
import withProtected from "../../../core/hoc/withProtected"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import classNames from "classnames"

const SingleStorePage = () => {
  const [loading, setLoading] = useState(true)
  const [store, setStore] = useState<Store | null>(null)
  const [categories, setCategories] = useState<Category[]>([])

  const params = useParams()

  const fetchStoreAndCategories = async (slug: string) => {
    setLoading(true)
    getStore(slug)
      .then((res) => {
        setStore(res!)

        getCategoriesByStore(res?.slug!).then((res) => {
          setLoading(false)
          setCategories(res!)
        })
      })
      .catch(() => {
        toast.error("Something went wrong!")
      })
  }

  useEffect(() => {
    const { slug } = params

    if (slug) {
      fetchStoreAndCategories(slug)
    }
  }, [params])

  if (loading && !store) {
    return (
      <div className="pt-6 pb-20">
        <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb
              paths={[
                {
                  name: "Stores",
                  route: "/stores",
                },
              ]}
            />
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  <Skeleton height={20} width={120} />
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  <Skeleton height={20} width={250} />
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-8"></div>
          </div>
        </div>
      </div>
    )
  }

  const storeTypeToStoreAvailability = (storeType: string) => {
    switch (storeType) {
      case "ONL":
        return "Online Only"
      case "INS":
        return "In-Store Only"
      case "BTH":
        return "Online & In-Store"
      default:
        return "N/A"
    }
  }

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Stores",
                route: "/stores",
              },
              {
                name: store?.name!,
                route: `/stores/${store?.slug}`,
                asLink: false,
              },
            ]}
          />

          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                {store?.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                You can view store details & assign categories
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                  <div className="flex items-center space-x-3">
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Store Info
                    </h2>

                    <div
                      className={classNames(
                        "rounded-full px-3 py-1 border",
                        store?.active
                          ? "bg-green-100 text-green-500 border-green-300 "
                          : "bg-red-100 text-red-500 border-red-300",
                      )}
                    >
                      {store?.active ? "Active" : "Inactive"}
                    </div>
                  </div>

                  <div>
                    <Link
                      to={`/stores/edit/${store?.slug}`}
                      className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                    >
                      <PencilAltIcon className="w-5 h-5 " />
                    </Link>
                  </div>
                </div>
                <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Slug
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.slug}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">URL</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <a className="text-primary-teal" href={store?.url}>
                          {store?.url ?? (
                            <span className="text-gray-500">N/A</span>
                          )}
                        </a>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Alter Text
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.alterText ?? "-"}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Promotional Label
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.promotionLabel ?? "-"}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Store Availability
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {storeTypeToStoreAvailability(store?.storeType ?? "-")}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Merchant ID
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.merchantID ?? "-"}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Cashback
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.cashback ?? "-"}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Created At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {dayjs(store?.createdAt?.toString()).format(
                          "YYYY-MM-DD - HH:mm:ss",
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Updated At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {dayjs(store?.updatedAt?.toString()).format(
                          "YYYY-MM-DD - HH:mm:ss",
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {store?.description ?? "-"}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>

            <section className="mt-10">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Assigned Categories
                  </h2>
                  {/* {allCategories && (
                    <AssignCategory
                      refetch={fetchStoreAndCategories}
                      storeSlug={store?.slug!}
                      categories={allCategories}
                    />
                  )} */}
                </div>
                <div>
                  <div className="flex flex-wrap px-4 pb-5 gap-2 sm:px-6">
                    {Array.isArray(categories) &&
                      categories.map((item) => {
                        return (
                          <div
                            className="flex items-center px-4 py-3 space-x-4 rounded-full bg-primary-blue "
                            key={item.slug}
                          >
                            <div className="text-sm text-white">
                              {item.name}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withProtected(SingleStorePage)
