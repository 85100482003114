import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { LoadingLayout } from "../layouts";

const withPublic = (Component: any) => {
  return (props: any) => {
    const { route } = useAuthenticator((context) => [context.route]);

    switch (route) {
      case "idle":
        return <LoadingLayout />;
      case "authenticated":
        return <Navigate to={{ pathname: "/" }} />;
      default:
        return (
          <>
            <Component {...props} />
          </>
        );
    }
  };
};

export default withPublic;
