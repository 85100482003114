import React from "react"
import { Popover } from "@mantine/core"
import { DatePicker, TimeInput } from "@mantine/dates"

interface ScheduleDateTimePickerProps {
  popoverTrigger: React.ReactNode;
  isCalendarOpened: boolean;
  setIsCalendarOpened: (isCalendarOpened: boolean) => void;
  selectedDateRange: [Date | null, Date | null];
  setSelectedDateRange: (dates: [Date | null, Date | null]) => void;
  uploadingTimeSlot: string
  setUploadingTimeSlot: (uploadingTimeSlot: string) => void;
  removingTimeSlot: string,
  setRemovingTimeSlot: (removingTimeSlot: string) => void;
  dateTimeSelectionHandler: () => void;
  dateTimeResetHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ScheduleDateTimePicker: React.FC<ScheduleDateTimePickerProps> = ({
                                                                         popoverTrigger,
                                                                         isCalendarOpened,
                                                                         setIsCalendarOpened,
                                                                         selectedDateRange,
                                                                         setSelectedDateRange,
                                                                         uploadingTimeSlot,
                                                                         setUploadingTimeSlot,
                                                                         removingTimeSlot,
                                                                         setRemovingTimeSlot,
                                                                         dateTimeSelectionHandler,
                                                                         dateTimeResetHandler,
                                                                       }) => {

  return (
    <div>
      <Popover
        opened={isCalendarOpened}
        onChange={setIsCalendarOpened}
        position="bottom-end"
        offset={8}
        closeOnClickOutside={false}
      >
        <Popover.Target>
          {popoverTrigger}
        </Popover.Target>
        <Popover.Dropdown>
          <DatePicker
            type="range"
            numberOfColumns={2}
            hideOutsideDates={false}
            value={selectedDateRange}
            onChange={setSelectedDateRange}
            classNames={{
              calendarHeaderControlIcon: "calendar-icon",
              day: "calendar-day",
              monthsListCell: "calendar-month-cell",
              yearsListCell: "calendar-year-cell",
            }}
            styles={{
              levelsGroup: {
                display: "flex",
                gap: "10px",
              },
              calendarHeader: {
                display: "inline-flex",
                alignItems: "center",
                width: "100%",
                marginBottom: "10px",
              },
              calendarHeaderControl: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
              },
              calendarHeaderLevel: {
                fontSize: "18px",
                fontWeight: 600,
                width: "100%",
                textAlign: "center",
              },
              weekday: {
                width: "40px",
                height: "100%",
                color: "#697386",
                fontWeight: 400,
              },
              monthsListCell: {
                width: "98px",
                height: "55px",
                borderRadius: "4px",
                textAlign: "center",
              },
              yearsListCell: {
                width: "98px",
                height: "55px",
                borderRadius: "4px",
                textAlign: "center",
              },
              day: {
                width: "40px",
                height: "40px",
                textAlign: "center",
                borderRadius: "4px",
              },
            }} />
          <div className={"mt-5 flex gap-x-4 items-center justify-between"}>
            <button onClick={dateTimeResetHandler} className={"p-3 border border-ephemeral-mist rounded-md"}>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.59003 7.15688L13.2569 2.49L14.59 3.82312L9.92315 8.49L14.59 13.1569L13.2569 14.49L8.59003 9.82312L3.92315 14.49L2.59003 13.1569L7.2569 8.49L2.59003 3.82312L3.92315 2.49L8.59003 7.15688Z"
                  fill="#001C33" />
              </svg>
            </button>
            <TimeInput
              value={uploadingTimeSlot}
              onChange={(event) => setUploadingTimeSlot(event.target.value)}
              className={"w-3/4"}
              classNames={{
                input: "schedule-time-input",
              }}
            />
            <TimeInput
              value={removingTimeSlot}
              onChange={(event) => setRemovingTimeSlot(event.target.value)}
              className={"w-3/4"}
              classNames={{
                input: "schedule-time-input",
              }}
            />
            <button onClick={dateTimeSelectionHandler}
                    className={"p-3 border border-ephemeral-mist rounded-md"}>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <path
                    d="M6.44571 11.8174L13.5708 4.26726C13.7389 4.08909 13.9351 4 14.1593 4C14.3835 4 14.5796 4.08909 14.7478 4.26726C14.9159 4.44543 15 4.65702 15 4.902C15 5.14699 14.9159 5.35857 14.7478 5.53675L7.03421 13.7327C6.86607 13.9109 6.6699 14 6.44571 14C6.22152 14 6.02535 13.9109 5.85721 13.7327L2.24213 9.902C2.07398 9.72383 1.99341 9.51225 2.00042 9.26726C2.00743 9.02227 2.095 8.81069 2.26314 8.63252C2.43129 8.45434 2.63096 8.36526 2.86215 8.36526C3.09335 8.36526 3.29302 8.45434 3.46117 8.63252L6.44571 11.8174Z"
                    fill="#001C33" />
                </g>
              </svg>
            </button>
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  )
}

export default ScheduleDateTimePicker