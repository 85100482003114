import withProtected from "../../../core/hoc/withProtected"
import React, { useEffect, useState } from "react"
import { CenteredContainer } from "../../../core/layouts"
import { useForm } from "react-hook-form"
import { Banner, ScheduledBanner } from "../../../types"
import PrimaryButton from "../../../core/components/Button"
import { useNavigate, useParams } from "react-router-dom"
import {
  deleteBannerPlace,
  editBannerPlace, getScheduledBanners,
  getSingleBannerPlace,
} from "../../../data/banner_data"
import toast from "react-hot-toast"
import BannerListDragDrop from "../components/BannerListDragDrop"
import ScheduledBannersList from "../components/ScheduledBannersList"

interface FieldValues {
  slugs: string[]
  // scheduledBanners: string[]
}

const EditPlace = () => {
  const params = useParams()
  const navigate = useNavigate()

  const {  setValue } = useForm<FieldValues>()
  const [loading, setLoading] = useState(true)

  const [Banners, setBanners] = useState<Banner[]>([])
  const [scheduledBanners, setScheduledBanners] = useState<ScheduledBanner[]>([])
  const [bannerSlugs, setBannerSlugs] = useState<string[]>([])

  useEffect(() => {
    setValue("slugs", bannerSlugs)
  }, [bannerSlugs])

  const [page, setPage] = useState("")
  const [place, setPlace] = useState("")

  async function onBannerChange(slugs: string[]) {
    setLoading(true)

    editBannerPlace({
      page,
      place,
      slugs,
    }).then((res) => {
      toast.success("Banner Place Edited Successfully")
      fetchBannerPlace(place,page)
    })

    setLoading(false)
  }


const [bannerCanBeSubmitted , setBannerCanBeSubmitted] = useState<boolean>(false)
  useEffect(() => {
    if (bannerSlugs && bannerSlugs.length > 0 && !loading) {
      // if (bannerCanBeSubmitted) {
      //   onBannerChange(bannerSlugs)
      //
      // }
      // setBannerCanBeSubmitted(true)
      onBannerChange(bannerSlugs)
    }
  }, [bannerSlugs])



  useEffect(() => {
    const { slug } = params

    if (slug) {
      const place = slug?.split("!BANNER-PLACE!")[0].toLowerCase()
      const page = slug?.split("!BANNER-PLACE!")[1].toLowerCase()


      setPlace(place)
      setPage(page)

      fetchBannerPlace(place, page)
      handleGetScheduleBanner(place, slug?.split("!BANNER-PLACE!")[1])
    }
  }, [params])

  const fetchBannerPlace = async (place: string, page: string) => {
    setLoading(true)

    getSingleBannerPlace(place, page)
      .then((res) => {
        const banners = res.data

        if (banners) {
          setBanners(banners)
          const bannerSlugs = banners.map((banner) => banner.slug)
          setValue("slugs", bannerSlugs)
        }

        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }


  const handleGetScheduleBanner = async (place: string, page: string) => {
    getScheduledBanners(page,place)
      .then((response) => {
        const scheduledBanners = response
        if (scheduledBanners) {
          console.log(scheduledBanners)

          setScheduledBanners(scheduledBanners)
          // const scheduledBannerSlugs = scheduledBanners.map((scheduledBanner:ScheduledBanner) => scheduledBanner.slug)
          // setValue("scheduledBanners", scheduledBannerSlugs)
          // setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
      })
  };

  const deleteBannerPlaceHandler = (page: string, place: string) => {
    deleteBannerPlace({
      page,
      place,
    }).then((res) => {
      toast.success("Banner Place Deleted Successfully")
      navigate("/banners")
    })
  }


  return (
    <CenteredContainer
      title="Edit Banner Placements"
      subtitle="You can Edit Banner Placements here."
      paths={[
        {
          name: "Banner",
          route: "/banners",
          asLink: true,
        },
        {
          name: "Edit Place",
          route: "/banners/add-place",
          asLink: false,
        },
      ]}
    >
      <div className="flex flex-col mt-8">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <BannerListDragDrop
                  setBannerSlugs={setBannerSlugs}
                  data={Banners}
                  loading={loading}
                />
                <ScheduledBannersList
                  data={scheduledBanners}
                  loading={loading}
                />
              </div>
              <div className="pt-5">
                <div className={` `}>
                  <div className="flex justify-between ">
                    <div>
                      <button
                        type="button"
                        tabIndex={0}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                          if (page && place) {
                            deleteBannerPlaceHandler(page, place)
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                    <div className={`space-x-3`}>
                      <button
                        onClick={() => {
                          navigate("/banners")
                        }}
                        disabled={loading}
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CenteredContainer>
  )
}

export default withProtected(EditPlace)
