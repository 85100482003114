import axios from "../utils/axiosNew"

const searchStores = async (
  q?: string,
  limit?: number,
) => {
  const params = {
    q,
    limit,
  }

  return await axios.get(`/admin/api/search/stores`, {
    params,
  }).then((response) => {
      return { data: response.data.storeResults, status: 200 }
    },
  ).catch((error) => {
    return {
      data: error.data,
      status: error.status,
    }
  })
}

export {
  searchStores,
}