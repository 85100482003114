/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import PrimaryButton from "../../../core/components/Button"
import {
  assignCategory,
  editCategory,
  getAllCategories,
  getCategoriesByStore,
  getCategory,
  reorderCategoryStores,
} from "../../../data/categories.data"
import {
  getStoresByCategory,
  removeOldNewArrivalStores,
} from "../../../data/stores.data"
import { Category } from "../../../types"

function AssignCategory({
  storeSlug,
  refetch,
}: {
  storeSlug?: string
  refetch: (categories: Category[]) => void
}) {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState("")
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const [categories, setCategories] = useState<Category[]>([])

  const inputRef = useRef<any>(null)

  const refetchAssignedCategories = async (slug: string) => {
    setLoading(true)

    try {
      const response = await getCategoriesByStore(slug)
      setLoading(false)
      return response
    } catch (error: any) {
      toast.error(error["message"] ?? "Somthing went wrong!")
    }
    setLoading(false)
  }

  const filteredCategories =
    query === ""
      ? categories
      : categories.filter((category) => {
          return category.name.toLowerCase().includes(query.toLowerCase())
        })

  const assignNewCategory = async () => {
    if (!selectedCategory) {
      return toast.error("Please select a category")
    }

    const category = await getCategory(selectedCategory.slug)

    if (category && !category.active) {
      await editCategory(category?.slug!, {
        active: true,
      })
    }

    setLoading(true)
    const response = await assignCategory(storeSlug!, selectedCategory?.slug!)

    if (response?.success!) {
      // add store on the top of the categoryStore order
      if (selectedCategory.slug === "new-arrivals") {
        const categoryStores = await getStoresByCategory(selectedCategory.slug)

        if (categoryStores?.success) {
          let ordered = categoryStores.data?.result.stores.map((item) => ({
            index: item.index,
            slug: item.slug,
          }))

          ordered = ordered
            ?.filter((i) => i.slug !== storeSlug!)
            .map((j) => ({ index: j.index + 1, slug: j.slug }))

          // structure new ordered items
          const newOrdered = [{ index: 1, slug: storeSlug! }, ...ordered!]

          // reorder categoryStores with new order
          const resultReOrder = await reorderCategoryStores(
            selectedCategory.slug,
            newOrdered
          )

          if (resultReOrder.data.success) {
            toast.success("Successfully Re-Ordered!")
          }
        }
        await removeOldNewArrivalStores()
          .then((res) => {
            toast.success(res.data.message)
          })
          .catch((err) => {
            toast.error(err.message)
          })
      }

      toast.success(response.message)
      setLoading(false)
      refetchAssignedCategories(storeSlug!).then((cates) => {
        if (cates) {
          refetch(cates)
          setSelectedCategory(undefined)
          inputRef.current.value = ""
        }
      })
    } else {
      setLoading(false)
      toast.error(response?.message ?? "Something went wrong!")
    }
  }

  useEffect(() => {
    refetchAssignedCategories(storeSlug!).then((cates) => {
      if (cates) {
        refetch(cates)
      }
    })
    getAllCategories().then((res) => {
      setCategories(res)
    })
  }, [storeSlug])

  return (
    <div className="flex items-center space-x-3">
      <Combobox
        as="div"
        value={selectedCategory}
        onChange={setSelectedCategory}
      >
        <div className="relative mt-1">
          <Combobox.Input
            ref={inputRef}
            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(category: any) => category.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredCategories.length > 0 && (
            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredCategories.map((category) => (
                <Combobox.Option
                  key={category.slug}
                  value={category}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-8 pr-4",
                      active
                        ? "bg-primary-blue text-primary-teal"
                        : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {category.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-1.5",
                            active ? "text-white" : "text-primary-blue"
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      <PrimaryButton
        disabled={loading}
        loading={loading}
        onClick={assignNewCategory}
        title="Assign"
      ></PrimaryButton>
    </div>
  )
}

export { AssignCategory }
