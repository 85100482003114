import React from 'react';

interface ResultsInfoProps {
  currentPage: number;
  pageSize: number;
  totalResults: number;
}

const ResultsInfo: React.FC<ResultsInfoProps> = ({
                                                   currentPage,
                                                   pageSize,
                                                   totalResults,
                                                 }) => {
  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(currentPage * pageSize, totalResults);

  return (
    <div className="font-inter text-sm font-normal leading-tight text-steel">
      {start} - {end} of {totalResults}
    </div>
  );
};

export default ResultsInfo;