import React, { useEffect, useState } from "react"

interface KeywordPillSearchBoxProps {
  onChangeTextHandler: (text: string) => void
}

const KeywordPillSearchBox = ({
  onChangeTextHandler,
}: KeywordPillSearchBoxProps) => {
  const [searchText, setSearchText] = useState<string>("")

  useEffect(() => {
    onChangeTextHandler(searchText)
  }, [searchText])

  return (
    <div className="relative flex items-center mt-1">
      <input
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value.trim().toLowerCase())
        }}
        type="text"
        name="search"
        id="search"
        placeholder="Search with keyword name (Fuzzy search)"
        className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
      />
      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <kbd className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
          Search
        </kbd>
      </div>
    </div>
  )
}

export default KeywordPillSearchBox
