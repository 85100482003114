/* eslint-disable jsx-a11y/alt-text */
import { TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import toast from "react-hot-toast"

interface ThumbnailDropZoneProps {
  onDrop: (file: any) => void
  height?: number
  width?: number
}

const ThumbnailDropZone = ({
  onDrop,
  height,
  width,
}: ThumbnailDropZoneProps) => {
  const [preview, setPreview] = useState<any>(null)

  const { getInputProps, getRootProps, isFocused, open } = useDropzone({
    accept: "image/webp",
    multiple: false,
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      toast.error(fileRejections[0].errors[0].message)
    },
    onDropAccepted: async (acceptedFiles) => {
      const fileSize = (acceptedFiles[0].size / (1024 * 1024)).toFixed(2)
      const fileSizeNumber = Number.parseFloat(fileSize)

      if (fileSizeNumber > 5) {
        return toast.error("File size exceeded")
      }

      if (height !== undefined || width !== undefined) {
        const image = new Image()
        image.src = URL.createObjectURL(acceptedFiles[0])
        const promise = new Promise((resolve, reject) => {
          image.onload = () => resolve(image)
          image.onerror = reject
        })

        await promise

        const imageWidth = image.naturalWidth
        const imageHeight = image.naturalHeight

        if (imageWidth !== width || imageHeight !== height) {
          return toast.error(
            `Image size should be ${width}x${height} but got ${imageWidth} x ${imageHeight}`
          )
        }
      }

      const file = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })

      setPreview(file)
      onDrop(file)
    },
  })

  const Preview = ({ file }: { file: any }) => {
    return (
      <div className="flex items-center space-x-4" key={file.name}>
        <div className="w-[300px] m-4 rounded-md h-auto">
          {preview && (
            <img className="rounded-md" src={file["preview"]} alt={""} />
          )}
        </div>

        <button
          onClick={() => {
            setPreview(null)
            onDrop(null)
          }}
          className="flex items-center justify-center w-10 h-10 transition-all bg-red-500 rounded-full hover:bg-red-700 min-w-10"
        >
          <TrashIcon className="w-6 h-6 text-white" />
        </button>
      </div>
    )
  }

  return (
    <>
      <section className="container">
        {preview ? (
          <Preview file={preview} />
        ) : (
          <div
            onClick={open}
            {...getRootProps({
              className: `${
                isFocused ? "border-primary-teal" : "border-gray-300"
              } flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 bg-white cursor-porinter border-dashed rounded-md `,
            })}
          >
            <input {...getInputProps()} />
            <div className="space-y-1 text-center">
              <svg
                className="w-12 h-12 mx-auto text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative font-medium bg-white rounded-md cursor-pointer text-primary-teal hover:text-primary-teal focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-teal"
                >
                  <span>Upload a file</span>
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">Webp images upto to 5MB</p>
              {height !== undefined && width !== undefined && (
                <p className="text-xs text-gray-500">
                  Image size should be {width} x {height}
                </p>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default ThumbnailDropZone
