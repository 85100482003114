import axiosNew from "../utils/axiosNew"
import { City } from "../types"
import BaseResponse from "./base_response"


interface GetCityData extends BaseResponse {
  cities: City[];
}

export const getCityStatus = async () => {
  try {
    const response = await axiosNew.get('/admin/api/city/status');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCities = async (startId: string, endId: string): Promise<any> => {
  try {
    const response = await axiosNew.get<GetCityData>('/admin/api/city/range', {
      params: {
        startId,
        endId,
      },
    });
    return response.data.cities;
  } catch (error) {
    throw error;
  }
};


export const getCityByName = async (cityName:string): Promise<City[]> => {
  try {
    const response = await axiosNew.get('/admin/api/city',{
      params:{
        cityName:cityName
      }
    })
    return response.data;
  } catch (error) {
    throw error;
  }
};
