import React, { useEffect, useState } from "react"
import classNames from "classnames"
import CopyToClipBoardIcon from "../../../icons/CopyToClipBoard.icon"
import { Switch } from "@headlessui/react"
import PrimaryButton from "../../../core/components/Button"
import toast from "react-hot-toast"
import { City, Location } from "../../../types"
import AddCityLocation from "./AddCityLocation"
import { EditLocations } from "../../../data/locations_data"
import { useNavigate } from "react-router-dom"

export const checkStringPrefix = (str: string) => {
  return (
    str.startsWith("https://maps.app.goo.gl/") || str.startsWith("https://goo.gl/maps/")
  )
}
export const emptyCity: City = {
  id: 0,
  province: "",
  district: "",
  cityName: "",
  postalCode: "",
  isActive: false,
  createdAt: "",
  updatedAt: null,
}

const EditLocationForm = ({
                            setShowAddNewLocationForm,
                            showAddNewLocationForm,
                            className,
                            isSeparateLocationForm = false,
                            onCancel,
                            editingLocation,
                          }: {

  setShowAddNewLocationForm: (showAddNewLocationForm: boolean) => void,
  showAddNewLocationForm: boolean,
  className?: string,
  isSeparateLocationForm?: boolean,
  onCancel?: () => void
  editingLocation: Location
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [locName, setLocName] = useState("")
  const [storeSlug, setStoreSlug] = useState("")
  const [locId, setLocId] = useState(1)
  const [city, setCity] = useState<City>(emptyCity)
  const [locAddress1, setLocAddress1] = useState("")
  const [locAddress2, setLocAddress2] = useState("")
  const [addressUrl, setAddressUrl] = useState("")
  const [isLocationActive, setIsLocationActive] = useState(true)

  const [locNameError, setLocNameError] = useState("")
  const [locAddressError, setLocAddressError] = useState("")
  const [addressUrlError, setAddressUrlError] = useState("")

  useEffect(() => {
    if (editingLocation) {
      setLocName(editingLocation.nameOfPlace)
      setLocAddress1(editingLocation.addressLine1)
      setLocAddress2(editingLocation.addressLine2)
      setCity(editingLocation.city)
      setAddressUrl(editingLocation.mapUrl)
      setIsLocationActive(editingLocation.isActive)
      setLoading(false)
    }
  }, [editingLocation])

  const handleSubmit = () => {
    let hasError = false

    if (!locName) {
      toast.error("Location name is required")
      setLocNameError("Location name is required")
      hasError = true
    } else {
      setLocNameError("")
    }

    if (!locAddress1 && !locAddress2) {
      toast.error("Location address is required")
      setLocAddressError("Location address is required")
      hasError = true
    } else {
      setLocAddressError("")
    }

    if (!addressUrl) {
      toast.error("Address Url is required")
      setAddressUrlError("Address Url is required")
      hasError = true
    } else {
      if (addressUrl && !checkStringPrefix(addressUrl)) {
        toast.error("Map URL must start with 'https://maps.app.goo.gl/' or 'https://goo.gl/maps/'")
        setAddressUrlError("Should start with 'https://maps.app.goo.gl/' or 'https://goo.gl/maps/'")
        hasError = true
      } else {
        setAddressUrlError("")
      }
    }

    if (!hasError) {
      let newLocationDataObj = {
        id: editingLocation.id,
        nameOfPlace: locName,
        addressLine1: locAddress1,
        addressLine2: locAddress2,
        city: city,
        mapUrl: addressUrl,
        isActive: isLocationActive,
        storeSlug: editingLocation.storeSlug,
        createdAt: editingLocation.createdAt,
        updatedAt: editingLocation.updatedAt,
      }
      EditLocations(newLocationDataObj).then((response) => {
        toast.success("location updated Successfully")
        resetForm()
        navigate(`/stores/edit/${editingLocation.storeSlug}/locations`)
      })
        .catch((error) => {
          toast.error("something went wrong")
          resetForm()
          navigate(`/stores/edit/${editingLocation.storeSlug}/locations`)
        })
    }
  }

  const resetForm = () => {
    setLocId(0)
    setLocName("")
    setLocAddress1("")
    setLocAddress2("")
    setAddressUrl("")
    setCity(emptyCity)
    setIsLocationActive(true)
  }

  const resetErrorStates = () => {
    setLocNameError("")
    setLocAddressError("")
    setAddressUrlError("")
  }

  const [copied, setCopied] = useState(false)

  const copyTextToClipboard = () => {
    if (addressUrl) {
      navigator.clipboard
        .writeText(addressUrl)
        .then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
          toast.success("Copied to clipboard!")
        }, () => {
          toast.error("Could not copy to clipboard!")
        })
    }
  }

  const handleCancel = () => {
    resetForm()
    resetErrorStates()
  }


  return (
    <>
      {loading ?
        <></>
        :
        <>
          <div
            className={`flex flex-col space-y-7 rounded-[7px] ${className ? className : "bg-walls-of-santorini bg-opacity-50 p-6"}`}>
            <div className={"sm:grid sm:grid-cols-5"}>
              <label
                htmlFor="locName"
                className="sm:col-span-2 block text-[15px] font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Location Name
              </label>
              <div className={"sm:col-span-3"}>
                <input
                  disabled={loading}
                  type="text"
                  value={locName}
                  onChange={(e) => setLocName(e.target.value)}
                  className={classNames(
                    locNameError ? "border border-pelati focus:outline-none focus:ring-pelati focus:border-pelati" : "border border-gray-300 focus:ring-primary-teal focus:border-primary-teal",
                    "flex-1 block w-full pr-12 rounded-md shadow-sm  sm:text-sm text-midnight-navy",
                  )}
                />
                {locNameError && (
                  <p className="text-pelati text-sm mt-1">{locNameError}</p>
                )}
              </div>
            </div>

            <div className={"sm:grid sm:grid-cols-5 sm:border-t-2 sm:border-ephemeral-mist sm:pt-7"}>
              <label
                htmlFor="locAddress"
                className="sm:col-span-2 block text-[15px] font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Location Address
              </label>
              <div className={"sm:col-span-3"}>
                <input
                  disabled={loading}
                  type="text"
                  value={locAddress1}
                  onChange={(e) => setLocAddress1(e.target.value)}
                  className={classNames(
                    locAddressError ? "border border-pelati focus:outline-none focus:ring-pelati focus:border-pelati" : "border border-gray-300 focus:ring-primary-teal focus:border-primary-teal",
                    "flex-1 block w-full pr-12 rounded-md shadow-sm sm:text-sm text-midnight-navy",
                  )}
                />
                <input
                  disabled={loading}
                  type="text"
                  value={locAddress2}
                  onChange={(e) => setLocAddress2(e.target.value)}
                  className={classNames(
                    locAddressError ? "border border-pelati focus:outline-none focus:ring-pelati focus:border-pelati" : "border border-gray-300 focus:ring-primary-teal focus:border-primary-teal",
                    "mt-2.5 flex-1 block w-full pr-12 rounded-md shadow-sm sm:text-sm text-midnight-navy",
                  )}
                />
                {locAddressError && (
                  <p className="text-red-500 text-sm mt-1">{locAddressError}</p>
                )}
              </div>
            </div>

            <div className={"sm:grid sm:grid-cols-5 sm:border-t-2 sm:border-ephemeral-mist sm:pt-7"}>
              <label
                htmlFor="locCity"
                className="sm:col-span-2 block text-[15px] font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                City (For Search)
              </label>
              <div className={"sm:col-span-3 xl:w-1/2"}>
                <AddCityLocation onAddCity={(city) => {
                  setCity(city)
                }} currentCity={city} />

              </div>
            </div>

            <div className={"sm:grid sm:grid-cols-5 sm:border-t-2 sm:border-ephemeral-mist sm:pt-7"}>
              <label
                htmlFor="addressUrl"
                className="sm:col-span-2 block text-[15px] font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Address URL (Google Maps)
              </label>
              <div className={"sm:col-span-3 relative"}>
                <input
                  disabled={loading}
                  type="text"
                  value={addressUrl}
                  onChange={(e) => setAddressUrl(e.target.value)}
                  className={classNames(
                    addressUrlError ? "border border-pelati focus:outline-none focus:ring-pelati focus:border-pelati" : "border border-gray-300 focus:ring-primary-teal focus:border-primary-teal",
                    "flex-1 block w-full pr-12 rounded-md shadow-sm sm:text-sm text-midnight-navy",
                  )}
                />
                <button onClick={copyTextToClipboard} className={"absolute top-0 right-0 pt-2 pr-2.5"}>
                  <CopyToClipBoardIcon copied={copied} />
                </button>
                {addressUrlError && (
                  <p className="text-red-500 text-sm mt-1">{addressUrlError}</p>
                )}
              </div>
            </div>

            <div className={"sm:grid sm:grid-cols-5 sm:border-t-2 sm:border-ephemeral-mist sm:pt-5"}>
              <label
                htmlFor="addressUrl"
                className="sm:col-span-2 block text-[15px] font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Status
              </label>
              <div className="flex items-center space-x-4">
                <p className={"text-sm"}>Inactive</p>
                <Switch
                  disabled={loading}
                  checked={isLocationActive}
                  onChange={setIsLocationActive}
                  className={classNames(
                    isLocationActive ? "bg-primary-teal focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal" : "bg-pelati focus:ring-2 focus:ring-offset-2 focus:ring-pelati",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none",
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isLocationActive ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                    )}
                  />
                </Switch>
                <p className={"text-sm"}>Active</p>
              </div>
            </div>

            <div className="pt-6 sm:border-t-2 sm:border-ephemeral-mist">
              <div className="flex justify-end space-x-3">
                <button
                  disabled={loading}
                  type="button"
                  onClick={onCancel || handleCancel}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                >
                  Cancel
                </button>
                <PrimaryButton
                  disabled={loading}
                  loading={loading}
                  type="button"
                  onClick={handleSubmit}
                  title={isSeparateLocationForm ? "Submit" : "Save"}
                />
              </div>
            </div>
          </div>
        </>}
    </>
  )
}

export default EditLocationForm