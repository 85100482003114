import { configureStore } from "@reduxjs/toolkit"
import cityReducer from "./features/citySlice"
import storeListReducer from "./features/storeListSlice"
import storeSlice from "./features/storeSlice"

export const store = configureStore({
  reducer: {
    city: cityReducer,
    storeList: storeListReducer,
    storeItem: storeSlice,
  },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;