import { Analytics } from "../types"
import axios from "../utils/axios"
import BaseResponse from "./base_response"

interface AnalyticsResponse extends BaseResponse {
  data: {
    [date: string]: Analytics[]
  }
}

// analytics/get-by-store-slug?slug=ekko

const getAnalyticsForStore = async (
  slug: string
): Promise<{ [date: string]: Analytics[] }> => {
  const AnalyticsForStore = await axios.get<AnalyticsResponse>(
    `/analytics/get-by-store-slug`,
    {
      params: {
        slug,
      },
    }
  )

  return AnalyticsForStore.data?.data
}

export { getAnalyticsForStore }
