import React, { useEffect, useState } from "react"

interface BannerDescriptionProps {
  color: string
  description: string
  viewPortValue: string
}

const BannerDescription = ({
  color,
  description,
  viewPortValue,
}: BannerDescriptionProps) => {
  const [fontSize, setFontSize] = useState("text-2xl")
  const [fontLeading, setFontLeading] = useState("leading-snug")

  useEffect(() => {
    if (viewPortValue === "mobileThumbnail") {
      setFontSize("text-sm")
      setFontLeading("leading-normal")
    } else {
      if (viewPortValue === "tabletThumbnail") {
        setFontSize("text-base")
        setFontLeading("leading-8")
      }
    }
  }, [viewPortValue, color])

  return (
    <p
      className={`font-poppins font-normal ${fontSize} ${fontLeading}`}
      style={{ color: color }}
    >
      {description}
    </p>
  )
}

export default BannerDescription
