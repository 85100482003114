import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { UploadItem } from "../../../types";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../core/components/Button";
import { createPress } from "../../../data/press.data";
import { uploadImage } from "../../../data/stores.data";
import withProtected from "../../../core/hoc/withProtected";
import { CenteredContainer } from "../../../core/layouts";
import ThumbnailDropZone from "../components/ThumbnailDropZone";


interface FieldValues {
  title: string;
  subtitle: string;
  url: string;
  slug: string;
  description: string;
  positive : boolean;
}



const AddPress = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<string | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FieldValues>();


  const nameTitle = watch("title");

  const navigate = useNavigate();

  useEffect(() => {
    setTitle(nameTitle);
  }, [nameTitle]);

  function getExtension(path: string) {
    const segments = path.split(".");
    const lastIndex = segments.length - 1;
    return segments[lastIndex];
  }

  async function onSubmit(data: FieldValues) {
    if (!thumbnail) {
      return toast.error("Logo must be required!");
    }

    // upload cover & logo
    const uploadList: UploadItem[] = [
      {
        key: `press/${data.slug}-${Date.now()}.${getExtension(thumbnail.name)}`,
        file: thumbnail,
      },
    ];

    setLoading(true);
    try {
      // upload requests list
      const uploadRequests: Promise<void>[] = uploadList.map((e) =>
        uploadImage(e)
      );

      // uploading all documents
      await Promise.all(uploadRequests);

      const body: { [key: string]: any } = {
        title: data.title,
        subtitle: data.subtitle,
        url: data.url,
        thumbnail: uploadList[0].key,
        slug: data.slug,
        description: data.description ?? "",
      };

      const response = await createPress(body);

      if (response.success) {
        toast.success(response.message);

        navigate("/press");
      } else {
        toast.error(response.message ?? "Error occured");
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message ?? "Error occured");
    }
  }

  return (
    <CenteredContainer
      title="Add Press"
      subtitle="You can press releases here"
      paths={[
        {
          name: "Press",
          route: "/press",
          asLink: true,
        },
        {
          name: "Add",
          route: "/press/add",
          asLink: false,
        },
      ]}
    >
      <div className="flex flex-col mt-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Title
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("title", {
                          required: "Title cannot be empty"

                        })}

                        autoComplete="name"
                        className={classNames(
                          errors && errors.title
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.title && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    {errors && errors.title && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.title.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="slug"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Slug
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("slug", {
                          required: "Slug cannot be empty"
                        })}
                        autoComplete="slug"
                        className={classNames(
                          errors && errors.slug
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.slug && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {errors && errors.slug && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.slug.message}
                      </div>
                    )}
                    <div className="mt-3">
                      {title?.length! > 0 && (
                        <div className="flex space-x-2">
                          <span className="text-sm">Suggestion: </span>
                          <button
                            type='button'
                            onClick={() => {
                              setValue(
                                'slug',
                                title
                                  ?.replace(/&/g, 'and')
                                  .split(' ')
                                  .join('')
                                  .toLowerCase()
                                  .split(/[^\w\d]/)
                                  .join('')!,
                              )
                            }}
                            className='px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md'
                          >
                            {title
                              ?.replace(/&/g, 'and')
                              .split(' ')
                              .join('')
                              .toLowerCase()
                              .split(/[^\w\d]/)
                              .join('')
                            }
                          </button>
                          {title?.trim().includes(' ') && (
                            <button
                              type='button'
                              onClick={() => {
                                setValue(
                                  'slug',
                                  title?.trim()
                                    ?.replace(/&/g, 'and')
                                    .replace(/\s\s+/g, ' ')
                                    .replace(/[^\w\s]/g, '')
                                    .replace(/\s/g, '-')
                                    .toLowerCase(),

                                )
                              }}
                              className='px-3 py-1 text-sm font-medium rounded-full bg-primary-teal text-primary-blue hover:-translate-y-[0.8px] hover:shadow-md active:translate-y-[0.8px] active:shadow-md'
                            >
                              {title?.trim()
                                ?.replace(/&/g, 'and')
                                .replace(/\s\s+/g, ' ')
                                .replace(/[^\w\s]/g, '')
                                .replace(/\s/g, '-')
                                .toLowerCase()

                              }
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="alterText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Subtitle
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        type="text"
                        {...register("subtitle", {
                          required: "Subtitle cannot be empty",
                        })}
                        disabled={loading}
                        autoComplete="alterText"
                        className={classNames(
                          errors && errors.subtitle
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.subtitle && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {errors && errors.subtitle && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.subtitle.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="storeUrl"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    URL
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("url")}
                        autoComplete="storeUrl"
                        className={classNames(
                          errors && errors.url
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.url && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {errors && errors.url && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.url.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Thumbnail
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <ThumbnailDropZone
                      onDrop={(file) => {
                        setThumbnail(file);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  disabled={loading}
                  {...register("description", {
                    required: "Description cannot be empty!",
                  })}
                  id="description"
                  name="description"
                  rows={3}
                  className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                  defaultValue={""}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about store.
                </p>

                {errors && errors.description && (
                  <div className="mt-2 text-sm text-red-600">
                    {errors.description.message}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end space-x-3">
              <button
                disabled={loading}
                type="button"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
              >
                Cancel
              </button>
              <PrimaryButton
                disabled={loading}
                loading={loading}
                type="submit"
                title={"Submit"}
              ></PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    </CenteredContainer>
  );
};

export default withProtected(AddPress);
