import withProtected from "../../../core/hoc/withProtected"

import { ShoppingBagIcon } from "@heroicons/react/outline"

import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { getStats } from "../../../data/stores.data"
import Skeleton from "react-loading-skeleton"
import ChartWrapper from "../components/Charts/Store/ChartWrapper"

const Dashboard = () => {
  const [storesCount, setStoresCount] = useState(0)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    getStats().then((res) => {
      setLoading(false)
      setStoresCount(res?.data?.storesCount ?? 0)
    })
  }, [])





  return (
    <div className="py-4">
      <div className="py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <div>
            <dl className="grid grid-cols-1 gap-5 mt-5 lg:grid-cols-2 xl:grid-cols-3">
              <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow sm:pt-6 sm:px-6">
                <dt>
                  <div className="absolute p-3 rounded-md bg-primary-blue-dark">
                    <ShoppingBagIcon
                      className="w-6 h-6 text-primary-teal"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    Total Stores
                  </p>
                </dt>
                <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                  {!loading ? (
                    <p className="text-2xl font-semibold text-gray-900">
                      {storesCount}
                    </p>
                  ) : (
                    <Skeleton width={50} height={25} />
                  )}
                  <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 sm:px-6">
                    <div className="text-sm">
                      <Link
                        to={"/stores"}
                        className="font-medium text-primary-blue hover:text-primary-blue"
                      >
                        View all
                        <span className="sr-only"> Total Stores stats</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className={`py-6`}>
          <ChartWrapper />
        </div>
      </div>
    </div>
  )
}

export default withProtected(Dashboard)
