import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Keyword } from "../../../types"
import KeywordPill from "./KeywordPill"

export default function CommonDisclosure(props: {
  keywords: Keyword[]
  onClickHandler: (keyword: Keyword) => void
  date: string
  count: number
}) {
  const { onClickHandler, keywords, date, count } = props

  const [expanded, setExpanded] = useState<false | number>(0)

  return (
    <div className={`mx-auto w-full rounded-xl bg-white space-y-2 m-1`}>
      <Accordion
        i={date}
        expanded={expanded}
        setExpanded={setExpanded}
        buttonText={date}
        panelText={keywords}
        onClickHandler={onClickHandler}
        count={count}
      />
    </div>
  )
}

const Accordion = (props: {
  i: any
  expanded: any
  setExpanded: any
  buttonText: string
  panelText: Keyword[]
  onClickHandler: (keyword: Keyword) => void
  count: number
}) => {
  const {
    i,
    expanded,
    setExpanded,
    buttonText,
    panelText,
    onClickHandler,
    count,
  } = props
  const isOpen = i === expanded

  return (
    <div>
      <motion.header
        initial={false}
        className={`flex w-full justify-between  items-center rounded-xl bg-primary-root-header px-4 pt-2 pb-3 text-left text-base lg:text-xl font-be-vietnam font-medium text-primary-dark-blue z-10 cursor-pointer`}
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        {buttonText}
        <div className={`flex flex-wrap`}>
          {count}
          <ChevronDownIcon
            className={`h-5 self-center flex-shrink-0 ml-3 relative  ${
              isOpen && "rotate-180"
            }`}
          />
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto" },
              collapsed: { height: 0, overflow: "hidden" },
            }}
            transition={{ bounce: { height: 0 } }}
          >
            <div
              className={`p-6 font-be-vietnam text-primary-blue bg-primary-root-header/30 z-0 rounded-xl mt-1`}
            >
              <div className={`flex flex-wrap`}>
                {panelText.map((keyword, index) => (
                  <KeywordPill
                    key={index}
                    keyword={keyword}
                    onClickHandler={onClickHandler}
                  />
                ))}
              </div>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}
