import axios from "../utils/axios"
import BaseResponse from "./base_response"
import { Location } from "../types"

interface AddLocation extends BaseResponse {
  data?: {
    subStoreData: Location[]
  }
}

interface DeleteLocation extends BaseResponse {
  data?: {
    subStoreData: Location
  }
}

interface GetLocationsData extends BaseResponse {
  data: Location[]
}

const getLocations = async (slug: string) =>
  axios
    .get<GetLocationsData>("/api/locations/getLocationStore", {
      params: {
        storeSlug: slug,
      },
    })
    .then((res) => res.data)

const AddSubStore = async (
  name: string,
  slug: string,
  url: string,
  address: string,
  storeSlug: string
) =>
  axios
    .post<BaseResponse>("/api/locations/createLocationStore", {
      name,
      slug,
      url,
      address,
      storeSlug,
    })
    .then((res) => res.data)

const deleteSubStore = (slug: string, storeSlug: string) =>
  axios
    .delete<BaseResponse>("/api/locations/removeLocationStore", {
      data: {
        slug,
        storeSlug,
      },
    })
    .then((res) => res.data)

const updateStoreData = (data: any) =>
  axios
    .post<BaseResponse>("/api/locations/editLocationStore", data)
    .then((res) => res.data)

export { AddSubStore, updateStoreData, getLocations, deleteSubStore }
