import { City, Location } from "../types"
import axiosNew from "../utils/axiosNew"
import BaseResponse from "./base_response"

interface GetLocationData  {
  success: boolean;
  message: string;
  data: Location[];
}

const getLocations = async (slug: string| null) =>{
  try {
    const response = await axiosNew.get('/admin/api/location/store',{
      params:{
        storeSlug:slug
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
const getLocationsByCity = async (city: string) =>{
  try {
    const response = await axiosNew.get<GetLocationData>('/admin/api/location/city',{
      params:{
        cityName:city
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const AddLocationsStore = async (slug:string,location: Location) =>{
  console.log(location)
  try {
    const response = await axiosNew.post('/admin/api/location/assign',{
      City: location.city,
      StoreSlug: slug,
      addressLine1:location.addressLine1,
      addressLine2:location.addressLine2,
      NameOfPlace:location.nameOfPlace,
      IsActive : location.isActive,
      mapUrl: location.mapUrl,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

const EditLocations = async (location: Location) =>{
  try {
    const response = await axiosNew.put('/admin/api/location',{
      id:location.id,
      storeSlug: location.storeSlug,
      nameOfPlace: location.nameOfPlace,
      addressLine1: location.addressLine1,
      addressLine2: location.addressLine2,
      city:location.city,
      mapUrl: location.mapUrl,
      isActive: location.isActive,
      createdAt:location.createdAt,
      updatedAt:location.updatedAt
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}




export { AddLocationsStore, getLocations,EditLocations }
