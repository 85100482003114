import { Category, UploadItem } from "../types";
import axios from "../utils/axios";
import BaseResponse from "./base_response";

interface GetAllCategories extends BaseResponse {
  data?: {
    count: number;
    categories: Category[];
  };
}
interface GetCategory extends BaseResponse {
  data?: Category;
}

interface GetCategoriesByStore extends BaseResponse {
  data?: Category[];
}

export const removeCategory = async (
  storeSlug: string,
  categorySlug: string
) => {
  const response = await axios.delete<BaseResponse>(
    "/api/categories/unAssignCategory ",
    {
      data: {
        storeSlug,
        categorySlug,
      },
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};

const assignCategory = async (storeSlug: string, categorySlug: string) => {
  const response = await axios.post<BaseResponse>(
    "/api/categories/assignCategory",
    {
      storeSlug,
      categorySlug,
    }
  );

  if (response.status === 200) {
    return response.data;
  }
};

const getCategoriesByStore = async (
  slug: string
): Promise<Category[] | undefined> => {
  const response = await axios.get<GetCategoriesByStore>(
    `/api/categoriesByStore/${slug}`
  );

  if (response.status === 200 && response.data.data) {
    return response.data.data;
  }
};

const getCategory = async (slug: string): Promise<Category | undefined> => {
  const response = await axios.get<GetCategory>(`/api/categories/${slug}`);

  if (response.status === 200 && response.data.data) {
    return response.data.data;
  }
};

const getAllCategories = async (): Promise<Category[]> => {
  let categories: Category[] = [];
  const response = await axios.get<GetAllCategories>("/api/categories");

  if (response.status === 200 && response.data.data) {
    categories = response.data.data.categories;
  }

  return categories;
};

const reorderCategories = async (categories: any[]) => {
  const response = await axios.post<BaseResponse>("/api/categories/reOrder", {
    categories,
  });

  return response;
};

const editCategory = async (slug: string, data: { [key: string]: any }) => {
  try {
    const body = {
      slug: slug,
      ...data,
    };

    var response = await axios.post<BaseResponse>("/api/categories/edit", body);

    if (response.status === 200 && response.data.success) {
      return response;
    } else {
      throw Error(response.data.message ?? "Error occured!");
    }
  } catch (error) {
    console.log(error);
    throw Error(`Edit Category Failed!`);
  }
};

const createCategory = async (data: any) => {
  try {
    var response = await axios.post<BaseResponse>(
      "/api/categories/create",
      data
    );

    if (response.status === 201 && response.data.success) {
      return response;
    } else {
      throw Error(response.data.message ?? "Error occured!");
    }
  } catch (error) {
    console.log(error);
    throw Error(`Create Store Failed!`);
  }
};

const uploadImage = async (item: UploadItem) => {
  var body = { key: item.key, action: "putObject" };
  try {
    var response = await axios.post("/api/getPresignedUrl", body);

    if (response.status === 200) {
      var _body = response.data;
      var url = _body["data"]["url"];

      console.log(`1. Get URL Success: ${item.key}`);

      const headers = {
        "Content-Type": item.file.type,
      };

      const upload = await fetch(url, {
        method: "PUT",
        body: item.file,
        headers: headers,
      });

      if (upload.status === 200) {
        console.log(`2. Upload Success: ${item.key}`);
        return;
      }

      throw new Error(`Upload Error`);
    }
  } catch (error) {
    console.log(error);
    throw Error(`Upload Failed: Key: ${item.key}`);
  }
};

const reorderCategoryStores = async (
  slug: string,
  stores: { index: number; slug: string }[]
) => {
  const response = await axios.post<BaseResponse>(
    "/api/categoryStores/reOrder",
    {
      categorySlug: slug,
      categoryStores: stores,
    }
  );

  return response;
};

export {
  getAllCategories,
  reorderCategories,
  uploadImage,
  createCategory,
  editCategory,
  getCategory,
  getCategoriesByStore,
  assignCategory,
  reorderCategoryStores,
};
