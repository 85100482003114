import React, { useEffect, useState } from "react"
import { Store } from "../../../types"
import { getStores } from "../../../data/stores.data"
import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"

interface storeDropdownProps {
  selectedStore: Store | null
  setSelectedStore: (store: Store) => void
  setIsStoresPopulated?: (value: boolean) => void
  withEmptyOption?: boolean
}

const StoreDropDown = ({
  setSelectedStore,
  selectedStore,
  setIsStoresPopulated,
  withEmptyOption,
}: storeDropdownProps) => {
  const [stores, setStores] = useState<Store[]>([])
  const [filteredStores, setFilteredStores] = useState<Store[]>([])
  const [query, setQuery] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (setIsStoresPopulated) {
      setIsStoresPopulated(false)
    }
    setLoading(true)
    getStores().then((res) => {
      if (withEmptyOption) {
        const emptyStore: Store = {
          active: true,
          createdAt: "",
          entity: "",
          name: "Do not Bind to a Store",
          url: "",
          cover: "",
          coverWithLogo: "",
          logo: "",
          index: 0,
          slug: "no-store",
          alterText: "",
          isLogoLight: false,
          description: "",
        }
        res?.data?.stores.unshift(emptyStore)
      }

      setStores(res?.data?.stores ?? [])
      setFilteredStores(res?.data?.stores ?? [])
      if (res?.data?.stores.length) {
        setSelectedStore(res?.data?.stores[0])
      }
      setLoading(false)
      if (setIsStoresPopulated) {
        setIsStoresPopulated(true)
      }
    })
  }, [])

  const onStoreSearch = (storeName: string) => {
    if (storeName === "") {
      setFilteredStores(stores)
    } else {
      const filteredStores = stores.filter((store) => {
        return store.name.toLowerCase().includes(storeName.toLowerCase())
      })
      setFilteredStores(filteredStores)
    }
  }

  useEffect(() => {
    if (query !== "") {
      onStoreSearch(query)
    } else {
      setFilteredStores(stores)
    }
  }, [query])

  return (
    <>
      {stores.length > 0 && !loading && (
        <Combobox as="div" value={selectedStore} onChange={setSelectedStore}>
          <div className="relative mt-1">
            <Combobox.Input
              className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal focus:outline-none focus:ring-1  sm:text-sm"
              displayValue={(store: Store) => store.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredStores.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredStores.map((singleStore: Store) => (
                  <Combobox.Option
                    key={singleStore.slug}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? "bg-primary-blue text-primary-teal"
                          : "text-gray-900"
                      }`
                    }
                    value={singleStore}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {singleStore.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-blue-600"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        </Combobox>
      )}
    </>
  )
}

export default StoreDropDown
