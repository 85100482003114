import React, { FC, useState } from "react";
import { Store } from "../../../types";

import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import classNames from "classnames";
import { MenuAlt4Icon } from "@heroicons/react/outline";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { reorderCategoryStores } from "../../../data/categories.data";

// a little function to help us with reordering the result
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  stores: Store[];
  categorySlug: string;
  setStores: React.Dispatch<React.SetStateAction<Store[]>>;
}

const ReOrderStoresUnderCategory: FC<Props> = ({
  stores,
  categorySlug,
  setStores,
}) => {
  const [loading, setLoading] = useState(false);

  async function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: Store[] = reorder(
      stores,
      result.source.index,
      result.destination.index
    );

    items.forEach((item, index) => {
      item.index = index + 1;
    });

    const _stores = items.map((item) => ({
      index: item.index,
      slug: item.slug,
    }));

    setLoading(true);

    const response = await reorderCategoryStores(categorySlug, _stores);

    if (response.status === 200) {
      toast.success(response.data.message);
    }

    setLoading(false);

    setStores(items);
  }

  return (
    <div className="pt-10 pb-10">
      <div className="">
        <div className="">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Re-Order Stores
              </h1>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            {loading && (
              <div className="space-y-2">
                <div
                  className={classNames(
                    "px-4 py-4 flex w-full items-center rounded-md border"
                  )}
                >
                  <div>
                    <MenuAlt4Icon
                      className={classNames("text-gray-500", "w-6 h-6 mr-4 ")}
                    />
                  </div>
                  <div className="mr-4">
                    <Skeleton className="w-8 h-8 rounded-full "></Skeleton>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10">
                    <Skeleton className="w-10 h-10 rounded-md" />
                  </div>
                  <Skeleton
                    className={classNames("ml-4 min-w-[300px]")}
                  ></Skeleton>
                </div>
                <div
                  className={classNames(
                    "px-4 py-4 flex w-full items-center rounded-md border"
                  )}
                >
                  <div>
                    <MenuAlt4Icon
                      className={classNames("text-gray-500", "w-6 h-6 mr-4 ")}
                    />
                  </div>
                  <div className="mr-4">
                    <Skeleton className="w-8 h-8 rounded-full "></Skeleton>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10">
                    <Skeleton className="w-10 h-10 rounded-md" />
                  </div>
                  <Skeleton
                    className={classNames("ml-4 min-w-[300px]")}
                  ></Skeleton>
                </div>
              </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classNames("space-y-2")}
                  >
                    {!loading &&
                      stores.map((item, index) => (
                        <Draggable
                          key={item.slug}
                          draggableId={item.slug}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={classNames(
                                snapshot.isDragging
                                  ? "bg-primary-blue-dark"
                                  : "bg-white",
                                "px-4 py-4 flex items-center rounded-md border"
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div>
                                <MenuAlt4Icon
                                  className={classNames(
                                    snapshot.isDragging
                                      ? "text-primary-teal"
                                      : "text-gray-500",
                                    "w-6 h-6 mr-4 "
                                  )}
                                />
                              </div>
                              <div className="mr-4">
                                <span className="flex items-center justify-center w-8 h-8 rounded-full text-primary-teal bg-primary-blue">
                                  {index + 1}
                                </span>
                              </div>
                              <div className="flex-shrink-0 w-10 h-10">
                                <img
                                  className="w-10 h-10 rounded-md"
                                  src={`${process.env.REACT_APP_STORES_ASSETS}/${item.cover}`}
                                  alt={item.alterText}
                                />
                              </div>
                              <p
                                className={classNames(
                                  snapshot.isDragging ? "text-white" : "",
                                  "ml-4"
                                )}
                              >
                                {item.name}
                              </p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReOrderStoresUnderCategory;
