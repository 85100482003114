import React, { useEffect, useRef, useState } from "react"
import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import { Location } from "../../../types";



const AddLocationStore = ({locationsList,
                            onAddLocation,
                          }: {
  storeSlug?: string;
  locationsList:Location[]
  onAddLocation: (locations: Location) => void;
}) => {
  const [query, setQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [locations, setLocations] = useState<Location[]>(locationsList);

  const inputRef = useRef<any>(null);

  const filteredLocations =
    query === ""
      ? locations
      : locations.filter((location) => {
        return location.nameOfPlace.toLowerCase().includes(query.toLowerCase());
      });

  const assignNewCategory = async () => {
    if (!selectedLocation) {
      return null;
    }

    onAddLocation(selectedLocation);
    setSelectedLocation(undefined);
    inputRef.current.value = "";
    setQuery("")
  };

  useEffect(() => {
    assignNewCategory().then()
  }, [assignNewCategory])

  useEffect(() => {
    setLocations(locationsList)
  }, [locationsList])

  return (

      <Combobox
        as="div"
        value={selectedLocation}
        onChange={setSelectedLocation}
      >
        <div className="relative mt-1">
          <Combobox.Input
            ref={inputRef}
            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-teal focus:outline-none focus:ring-1 focus:ring-primary-teal sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredLocations.length > 0 ? (
            <Combobox.Options
              className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredLocations.map((location) => (
                <Combobox.Option
                  key={location.id}
                  value={location}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 px-4",
                      active
                        ? "bg-primary-blue text-primary-teal"
                        : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {location.nameOfPlace}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-2.5",
                            active ? "text-white" : "text-primary-blue"
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          ) : <Combobox.Options
            className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"><div className={'relative cursor-default select-none py-2 px-4'}>No Locations Available</div></Combobox.Options>}
        </div>
      </Combobox>
  )
}

export default AddLocationStore