import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd"
import { Banner } from "../../../types"
import classNames from "classnames"


interface BannerListDragDropProps {
  data?: Banner[]
  loading: boolean
  setBannerSlugs: (slugs: string[]) => void
}

const BannerListDragDrop = ({
                              data = [],
                              loading,
                              setBannerSlugs,
                            }: BannerListDragDropProps) => {
  const [bannerList, setBannerList] = React.useState<Banner[]>(data)
  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result

    if (!destination) return

    const sourceIndex = source.index
    const destinationIndex = destination.index

    if (sourceIndex === destinationIndex) return

    const newBannerList = Array.from(bannerList)
    const [movedBanner] = newBannerList.splice(sourceIndex, 1)
    newBannerList.splice(destinationIndex, 0, movedBanner)

    // setBannerList(newBannerList)
    const newSlugs: string[] = newBannerList.map(item => item.slug);
    setBannerSlugs(newSlugs)
  }

  useEffect(() => {
    setBannerList(data)
  }, [data])

  const removeBannerFromTheList = (bannerSlug: string) => {
    // console.log(bannerSlug)
    const newBannerList = bannerList.filter(
      (banner) => banner.slug !== bannerSlug,
    )
    const newSlugs = newBannerList.map((banner) => banner.slug);
    setBannerSlugs(newSlugs);
    // console.log(newSlugs)
    // setBannerList(newBannerList)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames(
              // snapshot.isDraggingOver ? "bg-white" : "bg-white",
              "space-y-2",
            )}
          >
            {!loading &&
              bannerList &&
              bannerList.map((singleBanner, index) => (
                <Draggable
                  key={singleBanner.slug}
                  draggableId={singleBanner.slug}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={classNames(
                        snapshot.isDragging ? "bg-stone-50" : "bg-white",
                        "flex flex-col xl:flex-row xl:items-center rounded-md xl:h-18 border relative justify-between",
                      )}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        backgroundImage: `url(${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail})`,
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      <div className={`flex flex-row`}>
                        <img
                          className="h-14 w-auto "
                          src={`${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail}`}
                          alt=""
                        />
                        <h3 className={`my-auto pl-3`}>
                          {singleBanner.title}
                        </h3>
                      </div>

                      <div className={"flex items-center justify-end gap-x-12 my-4 xl:my-0"}>
                        <button
                          onClick={() =>{
                            removeBannerFromTheList(singleBanner.slug)
                          }}
                        >
                        <span
                          className={`text-sm font-medium mr-5 cursor-pointer text-primary-red`}
                        >
                          Remove
                        </span>
                        </button>
                      </div>
                    </div>

                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default BannerListDragDrop
