import React, { useEffect, useState } from "react"
import { Dialog } from "@headlessui/react"
import { motion, AnimatePresence } from "framer-motion"
import { Banner } from "../../../types"
import BannerHeading from "./BannerHeading"
import BannerDescription from "./BannerDescription"
import BannerButton from "./BannerButton"
import classNames from "classnames"
import BannerView from "./Preview/BannerView"

type ModalProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  data: Banner | null
  deleteBannerItem: (id: string) => void
}

interface ImageFile extends File {
  preview: string
  path: string
}

const tabs = [
  { name: "Mobile", current: true, value: "mobileThumbnail" },
  { name: "Desktop", current: false, value: "tabletThumbnail" },
]

const textColorTypes = [
  { name: "Dark Blue", value: "#001C33" },
  { name: "White", value: "#EDF3F7" },
]

const buttonTypes = [
  {
    name: "Primary",
    buttonTextColor: "#001C33",
    buttonBackgroundColor: "#74EADA",
  },
  {
    name: "Secondary",
    buttonTextColor: "#FFFFFF",
    buttonBackgroundColor: "#001C33",
  },
]

const BannerModalPreview = ({
                              isOpen,
                              setIsOpen,
                              data,
                              deleteBannerItem,
                            }: ModalProps) => {
  const [tabList, setTabList] = useState(tabs)
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [buttonText, setButtonText] = useState<string>("")
  const [textColor, setTextColor] = useState<string>("")
  const [buttonType, setButtonType] = useState(buttonTypes[0])
  const [textColorType, setTextColorType] = useState(textColorTypes[0])
  const [mobileThumbnail, setMobileThumbnail] = useState<ImageFile | null>(null)
  const [tabletThumbnail, setTabletThumbnail] = useState<ImageFile | null>(null)

  const [backgroundImage, setBackgroundImage] = useState<string>("")

  useEffect(() => {
    if (selectedTab.value) {
      if (selectedTab.value === "mobileThumbnail") {
        setBackgroundImage("none")
      }
      else {
        setBackgroundImage(`url("${tabletThumbnail?.preview}")`)
      }
    }
  }, [selectedTab.value, tabletThumbnail])

  useEffect(() => {
    if (data) {
      setMobileThumbnail({
        preview: `${process.env.REACT_APP_STORES_ASSETS}/${data.mobileThumbnail}`,
        path: data.mobileThumbnail,
        name: data.mobileThumbnail.split("/")[1],
      } as ImageFile)
      setTabletThumbnail({
        preview: `${process.env.REACT_APP_STORES_ASSETS}/${data.tabletThumbnail}`,
        path: data.tabletThumbnail,
        name: data.tabletThumbnail.split("/")[1],
      } as ImageFile)

      setTitle(data?.title || "")
      setDescription(data?.description || "")
      setButtonText(data?.buttonText || "")
      setTextColor(data?.textColor || "")
      setButtonType(
        buttonTypes.find(
          (type) => type.buttonTextColor === data?.buttonTextColor,
        ) || buttonTypes[0],
      )
      setTextColorType(
        textColorTypes.find((type) => type.value === data?.textColor) ||
        textColorTypes[0],
      )
    }
  }, [data])

  const changeSelectedTabHandler = (value: string) => {
    setTabList(
      tabs.map((tab) => {
        if (tab.name === value) {
          tab.current = true
          setSelectedTab(tab)
        } else {
          tab.current = false
        }
        return tab
      }),
    )
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={setIsOpen}
          as="div"
          className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto"
        >
          <div className="flex flex-col py-8 px-4 text-center">
            <Dialog.Overlay />
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-primary-blue opacity-50"></div>
            </div>

            <motion.div
              className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              initial={{
                opacity: 0,
                scale: 0.75,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ease: "easeIn",
                  duration: 0.15,
                },
              }}
            >
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div
                className="inline-block align-bottom bg-white rounded-lg text-left
                overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full "
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                      {mobileThumbnail?.preview &&
                        backgroundImage.length > 0 && (
                          <div className={`mx-auto flex flex-col items-center`}>
                            <BannerView mobileThumbnail={mobileThumbnail.preview} desktopThumbnail={backgroundImage}
                                        selectedView={selectedTab.value}
                                        title={title} description={description} buttonText={buttonText}
                                        textColor={textColor}
                                        buttonTextColor={buttonType.buttonTextColor}
                                        buttonBackgroundColor={buttonType.buttonBackgroundColor} />
                            <div className={"pt-3 w-full"}>
                              <div className={`w-full`}>
                                <div className="sm:hidden">
                                  <label htmlFor="tabs" className="sr-only">
                                    Select a tab
                                  </label>
                                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                                  <select
                                    id="tabs"
                                    name="tabs"
                                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                                    // defaultValue={tabs?.find((tab) => tab.current).name}
                                    value={selectedTab.name}
                                    onChange={(e) =>
                                      changeSelectedTabHandler(e.target.value)
                                    }
                                  >
                                    {tabList.map((tab) => (
                                      <option key={tab.name}>{tab.name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="hidden sm:block">
                                  <nav
                                    className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                                    aria-label="Tabs"
                                  >
                                    {tabList.map((tab, tabIdx) => (
                                      <div
                                        key={tab.name}
                                        className={classNames(
                                          tab.current
                                            ? "text-gray-900"
                                            : "text-gray-500 hover:text-gray-700",
                                          tabIdx === 0 ? "rounded-l-lg" : "",
                                          tabIdx === tabs.length - 1
                                            ? "rounded-r-lg"
                                            : "",
                                          "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer",
                                        )}
                                        onClick={(_e) => {
                                          changeSelectedTabHandler(tab.name)
                                        }}
                                        aria-current={
                                          tab.current ? "page" : undefined
                                        }
                                      >
                                        <span>{tab.name}</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            tab.current
                                              ? "bg-primary-teal"
                                              : "bg-transparent",
                                            "absolute inset-x-0 bottom-0 h-0.5",
                                          )}
                                        />
                                      </div>
                                    ))}
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse  justify-between">
                  <button
                    type="button"
                    tabIndex={0}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      if (data?.slug) {
                        deleteBannerItem(data?.slug)
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    tabIndex={0}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setIsOpen(false)}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}

export default BannerModalPreview
