import React, { useEffect, useState } from "react"
import { Analytics } from "../../../../../types"
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import { Line } from "react-chartjs-2"
import dayjs from "dayjs"

interface SingleStoreChartProps {
  data: { [date: string]: Analytics[] }
  deviceSelectionString: string
  startDate: Date
  endDate: Date
  needsDateFilter: boolean
  setNeedsDateFilter: (needsDateFilter: boolean) => void
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
)

export const options = {
  maintainAspectRatio: true,
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  aspectRatio: 4,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          return `Clicks: ${context.parsed.y}`
        },
      },
    },
  },
}

const SingleStoreChart = ({
  data,
  deviceSelectionString,
  startDate,
  endDate,
  needsDateFilter,
  setNeedsDateFilter,
}: SingleStoreChartProps) => {
  const [analytics, setAnalytics] = useState<{ [date: string]: Analytics[] }>(
    {}
  )
  const [labelsOfDates, setLabelsOfDates] = useState<string[]>([])
  const [dataSet, setDataSet] = useState<number[]>([])
  const [dataSetsLoaded, setDataSetsLoaded] = useState(false)
  const [deviceSelection, setDeviceSelection] = useState<string[]>([])

  useEffect(() => {
    if (needsDateFilter) {
      const START_DATE = dayjs(startDate)
      const END_DATE = dayjs(endDate)

      //   get array of dates between start and end date
      const dates = []
      let currentDateString = START_DATE

      while (
        currentDateString.format("DD-MM-YYYY") !== END_DATE.format("DD-MM-YYYY")
      ) {
        dates.push(currentDateString.format("DD-MM-YYYY"))
        currentDateString = currentDateString.add(1, "day")
      }

      dates.push(END_DATE.format("DD-MM-YYYY"))

      setLabelsOfDates(dates)
      setNeedsDateFilter(false)
    }
  }, [startDate, endDate, needsDateFilter])

  useEffect(() => {
    if (data) {
      // get only last 7 days
      const dates = Object.keys(data).slice(-7)

      const parsedDates = dates.map((date) => {
        const dateArray = date.split("-")

        return dayjs(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0])
      })

      // Sort the array in ascending order
      parsedDates.sort((a, b) => a.diff(b, "day"))

      // Convert the sorted dates back to strings
      const sortedDates = parsedDates.map((date) => date.format("DD-MM-YYYY"))

      setLabelsOfDates(sortedDates)
      setAnalytics(data)
    }
  }, [data])

  useEffect(() => {
    if (deviceSelectionString) {
      const deviceSelectionArray = deviceSelectionString.split(",")

      setDeviceSelection(deviceSelectionArray)
    }
  }, [deviceSelectionString, data])

  useEffect(() => {
    if (labelsOfDates.length > 0) {
      const datasets: number[] = []

      labelsOfDates.forEach((date) => {
        const analytics = data[date]

        if (analytics && analytics.length > 0) {
          datasets.push(analytics.length)
        }

        if (!analytics) {
          datasets.push(0)
        }
      })

      setDataSet(datasets)
      setDataSetsLoaded(true)
    }
  }, [labelsOfDates])

  const chartData = {
    labels: labelsOfDates,
    datasets: [
      {
        data: dataSet,
        borderColor: "rgb(0,67,113)",
        backgroundColor: "rgb(116,234,218)",
      },
    ],
  }

  useEffect(() => {
    if (deviceSelection.length > 0) {
      const datasets: number[] = []

      labelsOfDates.forEach((date) => {
        const analyticsOfThatDate = analytics[date] || []

        let deviceCount = 0

        analyticsOfThatDate.forEach((singleAnalytics) => {
          deviceSelection.map((device) => {
            if (
              singleAnalytics.deviceType.toString().trim() ==
              device.toString().trim()
            ) {
              deviceCount++
            }
          })
        })

        datasets.push(deviceCount)
      })

      setDataSet(datasets)
      setDataSetsLoaded(true)
    }
  }, [deviceSelection, data])

  return (
    <div className="w-full ">
      {dataSetsLoaded && <Line data={chartData} options={options} />}
    </div>
  )
}

export default SingleStoreChart
