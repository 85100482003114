import React, { useEffect, useRef, useState } from "react"
import { City } from "../../../types"
import { Combobox } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import { useAppDispatch, useAppSelector } from "../../../features/hooks/redux"
import { RootState } from "../../../store"
import { getCities, getCityStatus } from "../../../data/city_data"
import { setCities } from "../../../features/citySlice"

const AddCityLocation = ({ onAddCity, currentCity }: { onAddCity: (city: City) => void, currentCity: City }) => {
  const dispatch = useAppDispatch()
  const citiesFromStore = useAppSelector((state: RootState) => state.city.cities)
  const [query, setQuery] = useState("")
  const [selectedCity, setSelectedCity] = useState<City | undefined>(currentCity)
  const [newCities, setNewCities] = useState<City[]>(citiesFromStore)
  const [filteredCities, setFilteredCities] = useState<City[]>([])
  const inputRef = useRef<any>(null)

  // const filteredCities = query === ""
  //   ? newCities
  //   : newCities.filter((city) => city.cityName.toLowerCase().includes(query.toLowerCase()))

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const status = await getCityStatus()

        if (status.totalCities > citiesFromStore.length) {
          const chunkSize = 2000
          let allCities: City[] = []

          for (let i = status.firstCityId; i <= status.lastCityId; i += chunkSize) {
            const endId = Math.min(i + chunkSize - 1, status.lastCityId)
            const citiesChunk: City[] = await getCities(i.toString(), endId.toString())
            allCities = allCities.concat(citiesChunk)
          }
          dispatch(setCities(allCities))
          setNewCities(allCities)
        }

      } catch (error) {
        console.error("Error fetching cities:", error)
      }
    }

    fetchCities()
  }, [])

  useEffect(() => {
    // Always only output 10 cities
    if (query === "") {
      setFilteredCities(newCities.slice(0, 10))
    } else {
      setFilteredCities(newCities.filter((city) => city.cityName.toLowerCase().includes(query.toLowerCase())).slice(0, 10))
    }
  }, [query])

  useEffect(() => {
    if (currentCity) {
      setSelectedCity(currentCity)
    } else {
      setSelectedCity(undefined) // Reset when no city is selected
    }
  }, [currentCity])

  return (
    <Combobox
      as="div"
      value={selectedCity}
      onChange={(value) => {
        setSelectedCity(value)
        onAddCity(value)
        setQuery("")
      }}
    >
      <div className="relative mt-1">
        <Combobox.Input
          ref={inputRef}
          className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-teal focus:outline-none focus:ring-1 focus:ring-primary-teal sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(city: City) => city.cityName}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
          <SelectorIcon
            className="w-5 h-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredCities.length > 0 && (
          <Combobox.Options
            className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredCities.map((city) => (
              <Combobox.Option
                key={`${city.id} + ${city.cityName}`}
                value={city}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 px-4",
                    active
                      ? "bg-primary-blue text-primary-teal"
                      : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold",
                        )}
                      >
                        {city.cityName}
                      </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-2.5",
                          active ? "text-white" : "text-primary-blue",
                        )}
                      >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default AddCityLocation