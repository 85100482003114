/* eslint-disable jsx-a11y/alt-text */
import { TrashIcon } from "@heroicons/react/outline"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import toast from "react-hot-toast"

// function checkImageDimensions(file: File): Promise<any> {
//   const image = new Image();
//   image.src = URL.createObjectURL(file);
//   let width;
//   let height;

//   const promise = new Promise((resolve, reject) => {
//     image.onload = () => {
//       width = image.naturalWidth;
//       height = image.naturalHeight;

//       resolve({ width, height });
//     };

//     image.onerror = reject;
//   });

//   return promise;
// }

interface LogoDropZoneProps {
  onDrop: (file: any) => void
}

const LogoDropZone = ({ onDrop }: LogoDropZoneProps) => {
  const [preview, setPreview] = useState<any>(null)

  const { getInputProps, getRootProps, isFocused, open } = useDropzone({
    accept: "image/png, image/svg+xml, image/webp",
    multiple: false,
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      toast.error(fileRejections[0].errors[0].message)
    },
    onDropAccepted: async (acceptedFiles) => {
      const fileSize = (acceptedFiles[0].size / (1024 * 1024)).toFixed(2)
      const fileSizeNumber = Number.parseFloat(fileSize)

      if (fileSizeNumber > 1) {
        return toast.error("File size exceeded")
      }

      setPreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      )

      onDrop(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      )
    },
  })

  const Preview = ({ file }: { file: any }) => {
    return (
      <div className="flex items-center space-x-4" key={file.name}>
        <div className="min-w-[300px] max-w-[300px] mt-4 rounded-md overflow-hidden">
          <img
            className="object-contain min-w-[300px] w-[300px] h-[300px]"
            src={file["preview"]}
            alt="logo"
          />
        </div>
        <button
          onClick={() => {
            setPreview(null)
          }}
          type="button"
          className="shadow-sm inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all"
        >
          <TrashIcon className=" w-6 h-6 text-white" aria-hidden="true" />
        </button>
      </div>
    )
  }

  return (
    <>
      {preview ? (
        <aside>{preview && <Preview file={preview} />}</aside>
      ) : (
        <section className="container">
          <div
            onClick={open}
            {...getRootProps({
              className: `${
                isFocused ? "border-primary-teal" : "border-gray-300"
              } flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 cursor-porinter border-dashed rounded-md `,
            })}
          >
            <input {...getInputProps()} />
            <div className="space-y-1 text-center">
              <svg
                className="w-12 h-12 mx-auto text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative font-medium bg-transparent rounded-md cursor-pointer text-primary-teal hover:text-primary-teal focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-teal"
                >
                  <span>Upload a file</span>
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">PNG or SVG up to 1MB</p>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default LogoDropZone
