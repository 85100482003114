import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "@mantine/core/styles.css"
import "./styles/global.scss"
import "react-loading-skeleton/dist/skeleton.css"
import { Amplify } from "aws-amplify"
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react"

import { ColorSchemeScript, createTheme, MantineProvider } from "@mantine/core"

const theme = createTheme({
  /** Put your mantine theme override here */
})

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <ColorSchemeScript forceColorScheme="light" />
        <MantineProvider
          theme={theme}
          defaultColorScheme="light"
        >
          <App />
        </MantineProvider>
      </Authenticator.Provider>
    </AmplifyProvider></React.StrictMode>)