/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

type Path = {
  name: string;
  route?: string;
  asLink?: boolean;
};

interface BreadcrumbProps {
  paths: Path[];
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ paths }) => {
  const location = useLocation();
  return (
    <nav className="flex mb-8" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to={{ pathname: "/" }}
              className="text-gray-400 hover:text-gray-500"
            >
              <HomeIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {paths.map((page) => {
          const isActive = page.route === location.pathname;
          const asLink = page.asLink ?? true;

          return (
            <li key={page.route}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="flex-shrink-0 w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                {asLink ? (
                  <Link
                    to={`${page.route}`}
                    className={classNames(
                      isActive ? "text-gray-700" : "text-gray-500",
                      "ml-4 text-sm font-medium  capitalize hover:text-gray-700"
                    )}
                    aria-current={isActive ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                ) : (
                  <a
                    className={classNames(
                      isActive ? "text-gray-700" : "text-gray-500",
                      "ml-4 text-sm font-medium  capitalize hover:text-gray-700"
                    )}
                  >
                    {page.name}
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
