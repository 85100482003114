import React from "react"

const CopyToClipBoardIcon = ({copied}:{copied:boolean}) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M4.23756 16.9727C3.81022 16.9727 3.44439 16.8206 3.14007 16.5163C2.83575 16.212 2.68359 15.8461 2.68359 15.4188V4.54103C2.68359 4.11369 2.83575 3.74786 3.14007 3.44355C3.44439 3.13923 3.81022 2.98707 4.23756 2.98707H7.48146C7.6239 2.53383 7.90232 2.16153 8.31671 1.87016C8.7311 1.57879 9.18434 1.43311 9.67643 1.43311C10.1944 1.43311 10.6574 1.57879 11.0653 1.87016C11.4732 2.16153 11.7484 2.53383 11.8908 2.98707H15.1153C15.5426 2.98707 15.9085 3.13923 16.2128 3.44355C16.5171 3.74786 16.6693 4.11369 16.6693 4.54103V15.4188C16.6693 15.8461 16.5171 16.212 16.2128 16.5163C15.9085 16.8206 15.5426 16.9727 15.1153 16.9727H4.23756ZM4.23756 15.4188H15.1153V4.54103H13.5613V6.095C13.5613 6.31514 13.4869 6.49968 13.338 6.6486C13.189 6.79752 13.0045 6.87198 12.7844 6.87198H6.5685C6.34836 6.87198 6.16383 6.79752 6.0149 6.6486C5.86598 6.49968 5.79152 6.31514 5.79152 6.095V4.54103H4.23756V15.4188ZM9.67643 4.54103C9.89658 4.54103 10.0811 4.46657 10.23 4.31765C10.379 4.16873 10.4534 3.9842 10.4534 3.76405C10.4534 3.54391 10.379 3.35937 10.23 3.21045C10.0811 3.06153 9.89658 2.98707 9.67643 2.98707C9.45629 2.98707 9.27175 3.06153 9.12283 3.21045C8.97391 3.35937 8.89945 3.54391 8.89945 3.76405C8.89945 3.9842 8.97391 4.16873 9.12283 4.31765C9.27175 4.46657 9.45629 4.54103 9.67643 4.54103Z"
          fill={copied ? "#74EADA":"#697386"} />
      </g>
    </svg>
  )
}

export default CopyToClipBoardIcon