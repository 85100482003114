import { PencilAltIcon } from "@heroicons/react/outline"
import classNames from "classnames"
import React, { useState } from "react"
import toast from "react-hot-toast"
import { editStore } from "../../../data/stores.data"
import { Store } from "../../../types"

interface InputEditProps {
  store: Store
  type: string
  fieldName: keyof Store
  label: string
  textarea?: boolean
  refetch: (slug: string) => Promise<void>
  required?: boolean
  postOnClicked?: (value: boolean) => void
  maxLength?: number
}

interface Editable<T> {
  editable: boolean
  value: T
  url?: string | null
}

const InputEdit = ({
                     store,
                     type,
                     fieldName,
                     label,
                     textarea = false,
                     refetch,
                     required = true,
                     postOnClicked,
                     maxLength,
                   }: InputEditProps) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<Editable<any>>({
    editable: false,
    value: null,
  })

  const onUpdate = async () => {
    if (required) {
      if (value.value === null || value.value === "") {
        return toast.error(`Field "${fieldName}" must be required`)
      }
    }

    setLoading(true)

    if (type === "number" && isNaN(parseFloat(value.value))) {
      setLoading(false)
      return toast.error("Please enter a valid number")
    }

    const response = await editStore(store?.slug!, {
      [fieldName]: type === "number" ? parseFloat(value.value) : value.value.toString(),
    })
    setLoading(false)

    if (response.status === 200 && response.data.success) {
      if (
        postOnClicked &&
        fieldName === "promotionLabel" &&
        value.value.toString().trim() === ""
      ) {
        postOnClicked(false)
      } else {
        postOnClicked && postOnClicked(true)
      }

      toast.success(response.data.message)
      setValue({
        ...value,
        editable: false,
      })
      refetch(store.slug)
    } else {
      toast.error(response.data.message ?? "Something went wrong")
      refetch(store.slug)
    }
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        {value.editable ? (
          <div className="flex items-center justify-between">
            {textarea ? (
              <div className="flex flex-col w-full">
                <textarea
                  disabled={loading}
                  onChange={(event) => {
                    setValue({
                      editable: true,
                      value: event.target.value,
                    })
                  }}
                  rows={3}
                  className="block w-full max-w-lg border border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                  defaultValue={store?.[fieldName]?.toString()}
                  maxLength={maxLength ? maxLength : 255}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a few sentences about store. (Max:{" "}
                  {maxLength ? maxLength : 255} characters)
                </p>
              </div>
            ) : (
              <div className="relative flex items-center w-1/2 max-w-lg space-x-4 rounded-md shadow-sm">
                <input
                  defaultValue={store?.[fieldName]?.toString()}
                  onChange={(event) => {
                    setValue({
                      editable: true,
                      value: event.target.value,
                    })
                  }}
                  disabled={loading}
                  type="text"
                  autoComplete="off"
                  className={classNames(
                    "focus:ring-primary-teal focus:border-primary-teal",
                    "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm",
                  )}
                  maxLength={maxLength ? maxLength : 255}
                />
              </div>
            )}
            <div
              className={`flex flex-row space-x-4 items-center justify-center text-center `}
            >
              <button
                disabled={loading}
                onClick={onUpdate}
                className="inline-flex items-center justify-center px-4 py-2  text-sm font-medium text-white
                transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal
                 hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
              >
                {loading && (
                  <>
                    <svg
                      className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokeWidth={4}
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </>
                )}

                <span>Update</span>
              </button>
              <button
                disabled={loading}
                onClick={() => {
                  setValue({
                    editable: false,
                    value: store?.[fieldName]!.toString(),
                  })
                }}
                type="button"
                className="px-4 py-2  text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md
                shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-3 sm:mt-px sm:pt-2">
            <div className="text-sm">{store?.[fieldName]?.toString()}</div>
            <div>
              <button
                onClick={() => {
                  setValue({
                    editable: true,
                    value: store?.[fieldName]?.toString(),
                  })
                }}
                className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
              >
                <PencilAltIcon className="w-5 h-5 " />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { InputEdit }
