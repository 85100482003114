import React, { useEffect, useState } from "react"
import BannerHeading from "../BannerHeading"
import BannerDescription from "../BannerDescription"
import BannerButton from "../BannerButton"

interface BannerViewProps {
  mobileThumbnail: string
  desktopThumbnail: string
  selectedView: string

  title: string
  description: string
  buttonText: string
  textColor: string
  buttonTextColor: string
  buttonBackgroundColor: string
}

const BannerView = ({
                      mobileThumbnail,
                      desktopThumbnail,
                      selectedView,
                      textColor,
                      buttonTextColor,
                      buttonBackgroundColor,
                      buttonText,
                      title,
                      description,
                    }: BannerViewProps) => {

  return (
    <div
      className={`${selectedView === "mobileThumbnail" ? "w-[640px] h-[212px]" : selectedView === "tabletThumbnail" && "w-[768px] h-[256px]"} object-center flex rounded-lg  flex-col bg-no-repeat bg-cover bg-center  sm:pt-0`}
      style={{
        backgroundImage: `${selectedView === "tabletThumbnail" ? `${desktopThumbnail}` : 'none'}`,
      }}
    >
      <div
        className={`w-full max-w-2xl md:max-w-3xl mx-auto lg:max-w-4xl xl:max-w-7xl relative  grow flex ${
          selectedView === "mobileThumbnail" ? "w-[640px] h-[212px]" : selectedView === "tabletThumbnail" && "w-[768px] h-[256px]"
        }`}
      >
        <div
          className={`md:aspect-[3/1] lg:aspect-[3/1] h-full place-items-center `}
        >
          <img
            className={`mx-auto w-[320px] h-[192px] ${selectedView !== "mobileThumbnail" && "hidden"}`}
            src={mobileThumbnail}
            alt="promotion image banner"
          />
          <div
            className={`${selectedView === "mobileThumbnail" && "hidden"}`}
          >
            <div
              className={` h-full pt-2  ${selectedView === "mobileThumbnail" ? "hidden" : selectedView === "tabletThumbnail" && "hidden md:flex flex-col justify-center h-full w-3/5 lg:w-1/2 px-10 xl:px-12 space-y-0 "}`}
            >
              <div
                className={``}
              >
                <BannerHeading
                  viewPortValue={selectedView}
                  title={title}
                  color={textColor}
                />
              </div>
              <div
                className={`pt-3 xl:pt-6`}
              >
                <BannerDescription
                  viewPortValue={selectedView}
                  description={description}
                  color={textColor}
                />
              </div>
              <div className={`pt-4 xl:pt-10`}>
                {buttonText.trim().length > 0 && (
                  <BannerButton
                    buttonText={buttonText}
                    textColor={
                      buttonTextColor
                    }
                    backgroundColor={
                      buttonBackgroundColor
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerView