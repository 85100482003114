/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { ChangeEventHandler, useEffect, useState } from "react"
import withProtected from "../../../core/hoc/withProtected"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import { Location, Store } from "../../../types"
import { getStore, } from "../../../data/stores.data"
import { Link, useParams } from "react-router-dom"
import PrimaryButton from "../../../core/components/Button"
import { PlusIcon } from "@heroicons/react/outline"
import LocationListItem from "../components/LocationListItem"
import { getLocations } from "../../../data/locations_data"
import Skeleton from "react-loading-skeleton"




const ListLocations = () => {
  const params = useParams()
  const [trigger, setTrigger] = useState<boolean>(false)
  const [store, setStore] = useState<Store | null>(null)

  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<Location[] | null>(null);

  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number | null>(null);

  const fetchStore = async (slug: string) => {
    getStore(slug!)
      .then(async (store) => {
        setStore(store!)
      })
      .catch((err) => {
        console.error(err)
      })
  }


  const fetchLocationsFromApi = async () => {
    setLoading(true);
    const { slug } = params;
    try {
      const storeLocations:Location[] = await getLocations(slug!);
      setLocations(storeLocations);
      setFilteredLocations(storeLocations);
      setLoading(false)
    } catch (error) {
      console.error('Failed to fetch locations:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const { slug } = params
    fetchStore(slug!).then()
    fetchLocationsFromApi()
  }, [params, trigger])

  // const handleSearch: ChangeEventHandler<HTMLInputElement> = async ({
  //                                                                     target,
  //                                                                   }) => {
  //   const text = target.value;
  //   const searchText = text.toLowerCase();
  //   setSearchQuery(searchText);
  //
  //   if (searchText === "") {
  //     setFilteredLocations(null);
  //     setSearchQuery(null);
  //   }
  //
  //   setFilteredLocations(locations);
  // };

  const handleSearch: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const searchTerm = target.value.trim().toLowerCase();

    if (!searchTerm) {
      setFilteredLocations(locations);
    } else {
      const filtered = locations.filter(location =>
        location.nameOfPlace.toLowerCase().includes(searchTerm)
      );
      setFilteredLocations(filtered);
    }
  };


  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: store?.name!,
                route: `/stores/edit/${store?.slug}/locations`,
                asLink: false,
              }
            ]}
          />

          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Location for {store?.name}</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the locations in Mintpay
            </p>
          </div>

          <div className={'mt-5 flex flex-col-reverse lg:flex-row lg:items-center lg:justify-between'}>
            <div className="flex flex-col w-full">
              <div className="w-full lg:w-3/4 xl:w-[42%]">
                <div>
                  <div className="relative flex items-center mt-1">
                    <input
                      onChange={handleSearch}
                      type="text"
                      name="search"
                      id="search"
                      placeholder="Search with category name"
                      className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                    />
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                      <kbd
                        className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
                        ⌘K
                      </kbd>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 lg:mb-0 lg:ml-16 lg:flex-none">
              <Link to={`/stores/edit/${store?.slug}/locations/add`}>
                <PrimaryButton
                  title="Add Location"
                  icon={<PlusIcon className="w-5 h-5" />}
                />
              </Link>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            {/*<div className="flex pb-4 ">*/}
            {/*  <div className="hidden sm:block">*/}
            {/*    {searchQuery ? (*/}
            {/*      <p className="text-sm text-gray-500">*/}
            {/*        <span className="font-medium">*/}
            {/*          {filteredLocations?.length}*/}
            {/*        </span>{" "}*/}
            {/*        Results for{" "}*/}
            {/*        <span className="font-medium">"{searchQuery}"</span>*/}
            {/*      </p>*/}
            {/*    ) : (*/}
            {/*      <p className="text-sm text-gray-500">*/}
            {/*        Showing <span className="font-medium">1</span> to{" "}*/}
            {/*        <span className="font-medium">{locations?.length}</span> of{" "}*/}
            {/*        <span className="font-medium">{total}</span> results*/}
            {/*      </p>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="-mx-4 -my-2 overflow-x-auto  sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="w-full min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900 pl-6 pr-5"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900 pl-6 pr-5"
                      >
                        Location Address
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900 pl-6 pr-5"
                      >
                        Google URL
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900 pl-6 pr-10"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-10"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                        Array.from({ length: 10 }).map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <Skeleton
                                className="inline-flex w-full ml-4 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                              />
                            </td>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <Skeleton
                                className="inline-flex w-1/3 ml-4 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                              />
                            </td>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <Skeleton
                                className="inline-flex w-1/3 ml-4 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                              />
                            </td>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <Skeleton
                                className="inline-flex ml-4 w-1/3 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                              />
                            </td>
                            <td className="flex py-4 pl-4 pr-3 justify-center text-sm whitespace-nowrap sm:pl-6">
                              <Skeleton
                                className="inline-flex ml-4 w-1/3 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                              />
                            </td>
                          </tr>
                        )))
                      :
                      <> {filteredLocations && filteredLocations.length === 0 && (
                        <tr>
                          <td
                            colSpan={4}
                            className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6"
                          >
                            <span className="text-gray-500">
                              No matched results for the query
                            </span>
                          </td>
                        </tr>
                      )}
                        {filteredLocations?.map((location) => (
                          <LocationListItem store={store!} locationData={location}/>
                        ))}</>}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withProtected(ListLocations)
