/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react"
import withProtected from "../../../core/hoc/withProtected"
import { useNavigate, useParams } from "react-router-dom"
import { Location, Store } from "../../../types"
import { getStore } from "../../../data/stores.data"
import { Breadcrumb } from "../../../core/components/Breadcrumb"
import AddNewLocationForm from "../components/AddNewLocationForm"
import { AddLocationsStore } from "../../../data/locations_data"
import toast from "react-hot-toast"

const AddLocations = () => {
  const navigate = useNavigate()

  const params = useParams()
  const [trigger, setTrigger] = useState<boolean>(false)
  const [store, setStore] = useState<Store | null>(null)

  const fetchStore = async (slug: string) => {
    getStore(slug!)
      .then(async (store) => {
        setStore(store!)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    const { slug } = params
    fetchStore(slug!).then()
  }, [params, trigger])

  const AddLocations = async (slug: string | undefined, location: Location) => {
    try {
      await AddLocationsStore(slug!, location);
      toast.success("Location Added Successfully!");
      navigate(`/stores/edit/${store?.slug}/locations`)
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const [showAddNewLocationForm, setShowAddNewLocationForm] = useState(true)
  const [locations, setLocations] = useState<Location[]>([]);


  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: store?.name!,
                route: `/stores/edit/${store?.slug}/locations`,
              },
              {
                name: 'Add Location',
                route: `/stores/edit/${store?.slug}/locations/add`,
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex-auto sm:pb-6 sm:border-b-2 sm:border-ephemeral-mist">
            <h1 className="text-xl font-semibold text-gray-900">Add Location</h1>
            <p className="mt-2 text-sm text-gray-700">
              You can add locations here
            </p>
          </div>
          <div className="flex flex-col mt-8">
            <AddNewLocationForm onSave={(values)=> {
              AddLocations(store?.slug,values)

            }} setShowAddNewLocationForm={setShowAddNewLocationForm} showAddNewLocationForm={showAddNewLocationForm}
                                className={'bg-transparent'} isSeparateLocationForm={true}
                                onCancel={()=> navigate(`/stores/edit/${store?.slug}/locations`)}
             locationList={locations}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withProtected(AddLocations)
