import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import { getStoresByCategory } from "../../../data/stores.data";
import { Category, Store } from "../../../types";
import dayjs from "dayjs";
import { PencilAltIcon } from "@heroicons/react/outline";
import { getCategory } from "../../../data/categories.data";

import ReOrderStoresUnderCategory from "../components/ReOrderStoresUnderCategory";
import withProtected from "../../../core/hoc/withProtected";
import { Breadcrumb } from "../../../core/components/Breadcrumb";

const SingleCategoryPage = () => {
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState<Category | null>(null);
  const [stores, setStores] = useState<Store[]>([]);

  const params = useParams();

  const fetchStoreAndCategories = async (slug: string) => {
    setLoading(true);
    getCategory(slug)
      .then((res) => {
        setCategory(res!);

        getStoresByCategory(res?.slug!).then((res) => {
          setLoading(false);
          setStores(res!.data?.result.stores!);
        });
      })
      .catch(() => {
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    const { slug } = params;

    if (slug) {
      fetchStoreAndCategories(slug);
    }
  }, [params]);

  if (loading && !category) {
    return (
      <div className="pt-6 pb-20">
        <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <Breadcrumb
              paths={[
                {
                  name: "Categories",
                  route: "/categories",
                  asLink: false,
                },
              ]}
            />
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  <Skeleton height={20} width={120} />
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  <Skeleton height={20} width={250} />
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-8"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Categories",
                route: "/categories",
              },
              {
                name: category?.name!,
                route: `/categories/${category?.slug}`,
                asLink: false,
              },
            ]}
          />
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                {category?.name}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                You can view category details & re-order stores under this
                category
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-8">
            <section>
              {stores && category && (
                <ReOrderStoresUnderCategory
                  setStores={setStores}
                  stores={stores}
                  categorySlug={category?.slug!}
                />
              )}
            </section>
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Category Info
                  </h2>

                  <div>
                    <Link
                      to={`/categories/edit/${category?.slug}`}
                      className="inline-flex items-center justify-center w-8 h-8 min-w-[32px] min-h-[32px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto "
                    >
                      <PencilAltIcon className="w-5 h-5 " />
                    </Link>
                  </div>
                </div>
                <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {category?.name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Slug
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {category?.slug}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Created At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {dayjs(category?.createdAt?.toString()).format(
                          "YYYY-MM-DD - HH:mm:ss"
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Updated At
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {dayjs(category?.updatedAt?.toString()).format(
                          "YYYY-MM-DD - HH:mm:ss"
                        )}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {category?.description}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProtected(SingleCategoryPage);
