import { Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { MainLayout } from "../layouts";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { LoadingLayout } from "../layouts";

const withProtected = (Component: any) => {
  return (props: any) => {
    const { route } = useAuthenticator((context) => [context.route]);

    switch (route) {
      case "idle":
        return <LoadingLayout />;
      case "authenticated":
        return (
          <>
            <Sidebar />
            <MainLayout>
              <Component {...props} />
            </MainLayout>
          </>
        );
      default:
        return <Navigate to={{ pathname: "/login" }} />;
    }
  };
};

export default withProtected;
