import React, { useCallback, useState } from "react"
import { LongPressDetectEvents, useLongPress } from "use-long-press"
import { motion } from "framer-motion"
import { PlusIcon } from "@heroicons/react/solid"
import { slugifyWord } from "../../../data/stores.data"
import { editKeyword } from "../../../data/keywords_data"
import toast from "react-hot-toast"

interface subKeyword {
  keyword: string
  isActive: boolean
}

interface keywordPillProps {
  keyword: {
    keyword: string
    isActive: boolean
    count: number
    groupedKeywords: subKeyword[]
  }
  onClickHandler: (keyword: keywordPillProps["keyword"]) => void
  setSelectedSubKeywords?: (
    keyword: keywordPillProps["keyword"],
    subKeyword: string
  ) => void
  notFound?: boolean
}

const KeywordPill = ({
  keyword,
  onClickHandler,
  setSelectedSubKeywords,
  notFound,
}: keywordPillProps) => {
  const [showSubKeywords, setShowSubKeywords] = useState<boolean>(false)
  const [showKeyword, setShowKeyword] = useState<boolean>(true)

  const callback = useCallback(() => {
    setShowKeyword(false)
    editKeyword({
      slug: keyword.keyword,
      active: !keyword.isActive,
      description: null,
      name: null,
    }).then((res) => {
      toast.success("Keyword won't be shown in the results anymore")
    })
  }, [])

  const bind = useLongPress(callback, {
    onCancel: (event, meta) => onClickPill(),
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: LongPressDetectEvents.BOTH,
  })

  const onClickPill = () => {
    onClickHandler(keyword)
  }

  const onClickSubPill = (subKeyword: subKeyword) => {
    if (setSelectedSubKeywords) {
      setSelectedSubKeywords(keyword, subKeyword.keyword)
    }
  }

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          scale: 0.5,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        exit={{
          opacity: 0,
          scale: 0.5,
        }}
        whileHover={{
          scale: 1.025,
        }}
        whileTap={{
          scale: 0.975,
        }}
        // onClick={onClickPill}
        {...bind()}
        key={keyword.keyword}
        className={`flex keywords-center px-3 py-1.5 space-x-4 rounded-full m-1 cursor-pointer
         ${keyword.isActive ? "bg-primary-blue" : "bg-gray-200"}
         ${showSubKeywords ? "ring ring-1 ring-primary-blue" : ""}
         ${notFound ? "bg-red-500" : ""}
         ${showKeyword ? "" : "hidden"}
         `}
      >
        <div
          id={keyword.keyword}
          className={`flex flex-row text-sm 
          ${keyword.isActive ? "text-white" : "text-primary-blue-dark"}
          ${showSubKeywords ? "relative top-0.5" : ""}
          `}
        >
          {slugifyWord(keyword.keyword).replace(/-/g, " ")}

          {keyword.groupedKeywords.length > 0 && (
            <div
              id={keyword.keyword}
              className={`ml-1 bg-white rounded-full w-5 h-5 grid place-content-center text-primary-blue-dark`}
            >
              {showSubKeywords ? (
                <PlusIcon className="w-3 h-3 rotate-45" />
              ) : (
                keyword.count
              )}
            </div>
          )}
        </div>
      </motion.div>
      {showSubKeywords &&
        keyword.groupedKeywords.map((subKeyword, idx) => (
          <motion.div
            initial={{
              opacity: 0,
              scale: 0.5,
            }}
            animate={{
              opacity: 1,
              scale: 1,
            }}
            exit={{
              opacity: 0,
              scale: 0.5,
            }}
            whileHover={{
              scale: 1.025,
            }}
            whileTap={{
              scale: 0.975,
            }}
            onClick={() => onClickSubPill(subKeyword)}
            {...bind()}
            key={idx}
            className={`flex keywords-center px-2 py-1.5 space-x-4 rounded-full m-1 cursor-pointer border-solid border-2  ${
              subKeyword.isActive
                ? "bg-primary-blue"
                : "bg-gray-200  border-primary-blue"
            }`}
          >
            <div
              className={`flex flex-row text-sm ${
                subKeyword.isActive ? "text-white" : "text-primary-blue-dark"
              }`}
            >
              {subKeyword.keyword}
            </div>
          </motion.div>
        ))}
    </>
  )
}

export default KeywordPill
