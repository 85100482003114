import React, { FC } from "react"
import { Link, useParams } from "react-router-dom"
import { Location, Store } from "../../../types"

interface LocationListItemProps {
  store: Store;
  locationData:Location
}

const LocationListItem:FC<LocationListItemProps> = ({store,locationData}) => {
  return (
    <tr>
      <td className="py-4 text-left text-sm whitespace-nowrap pl-6 pr-5">{locationData.nameOfPlace}</td>
      <td className="py-4 text-sm text-gray-500 whitespace-nowrap pl-6 pr-5">
        <span>{locationData.addressLine1}</span>
        {locationData.addressLine1.endsWith(",") ? " " : " , "}
        <span>{locationData.addressLine2}</span>
      </td>
      <td
        className="py-4 text-sm text-gray-500 whitespace-nowrap pl-6 pr-5">{locationData.mapUrl.length > 35 ? locationData.mapUrl.slice(1, 35) + "..." : locationData.mapUrl}</td>
      <td className="py-4 text-xs text-gray-500 whitespace-nowrap pl-6 pr-10"><div className={`px-2.5 py-1.5 w-20 rounded-[19px] font-semibold inline-flex items-center justify-center ${locationData.isActive ? 'text-kilkenny bg-toxic-latte':'text-lady-in-red bg-misty-rose'}`}>{locationData.isActive ? 'Active' : 'Inactive'}</div></td>
      <td className="relative py-4 pl-3 space-x-4 text-sm font-medium text-right whitespace-nowrap pr-10">
        <Link
          to={`/stores/edit/${store?.slug}/locations/edit/${locationData.id}`}
          className="text-primary-blue hover:text-primary-blue-dark"
        >
          Edit
          {/* <span className="sr-only">, {person.name}</span> */}
        </Link></td>
    </tr>
  )
}

export default LocationListItem