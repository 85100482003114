import React, { useEffect, useState } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import LastPageIcon from '../components/Assets/LastPage.Icon';
import FirstPageIcon from '../components/Assets/FirstPage.Icon';

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  onPageChange: ReactPaginateProps['onPageChange'];
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange, currentPage }) => {
  // const [MarginPagesDisplayed, setMarginPagesDisplayed] = useState(2);
  // const [PageRangeDisplayed, setPageRangeDisplayed] = useState(2);
  const [pageMargin, setPageMargin] = useState(5);

  useEffect(() => {
    window.scrollTo(0, 0);

    // if (currentPage === 1 || currentPage === pageCount) {
    //   setMarginPagesDisplayed(3);
    //   setPageRangeDisplayed(1);
    // } else {
    //   setMarginPagesDisplayed(2);
    //   setPageRangeDisplayed(2);
    // }
  }, [currentPage]);

  const handleFirstPage = () => {
    if (onPageChange) {
      onPageChange({ selected: 0 });
    }
  };

  const handleLastPage = () => {
    if (onPageChange) {
      onPageChange({ selected: pageCount - 1 });
    }
  };

  useEffect(() => {
    if (currentPage === 1 || currentPage === pageCount || currentPage === pageCount - 1) {
      setPageMargin(5);
    } else {
      setPageMargin(4);
    }
  }, [currentPage]);

  return (
    <div className={'flex items-center justify-center gap-x-2.5'}>
      <button
        data-testid={'first-page-button'}
        disabled={currentPage === 1}
        onClick={handleFirstPage}
        className="inline-flex items-center p-2 rounded-full bg-calm-waters"
      >
        <FirstPageIcon disabled={currentPage === 1} />
      </button>
      <ReactPaginate
        previousLabel={null}
        nextLabel={null}
        breakLabel={''}
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={pageMargin}
        onPageChange={onPageChange}
        containerClassName={'flex items-center justify-center text-sm text-steel mr-2 md:mr-0 font-normal font-inter'}
        pageLinkClassName={'px-3 py-3 '}
        previousLinkClassName={'hidden inline-flex items-center mr-3 px-3 py-2 rounded-md'}
        activeClassName={'px-1 py-2 rounded-lg text-midnight-dreams bg-heart-of-ice border border-lucid-dreams'}
        breakClassName={'break-me'}
        nextLinkClassName={'hidden inline-flex items-center ml-3 px-3 py-2 rounded-md'}
        disabledClassName={'opacity-50 cursor-not-allowed'}
        forcePage={currentPage - 1}
      />
      <button
        data-testid={'last-page-button'}
        disabled={currentPage === pageCount}
        onClick={handleLastPage}
        className="inline-flex items-center p-2 rounded-full bg-calm-waters"
      >
        <LastPageIcon disabled={currentPage === pageCount} />
      </button>
    </div>
  );
};

export default Pagination;
