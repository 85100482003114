import { PlusIcon } from "@heroicons/react/outline";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Category } from "../../../types";
import Skeleton from "react-loading-skeleton";
import PrimaryButton from "../../../core/components/Button";
import { getAllCategories } from "../../../data/categories.data";
import withProtected from "../../../core/hoc/withProtected";
import { Breadcrumb } from "../../../core/components/Breadcrumb";

const CategoriesPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllCategories()
      .then((res) => {
        setLoading(false);
        setCategories(res);
      })
      .catch((err) => {});
  }, []);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const text = target.value;
    const searchText = text.toLowerCase();

    const _filteredStores = categories.filter((str, index) =>
      str.name.toLowerCase().includes(searchText)
    );

    setFilteredCategories(_filteredStores);
  };

  return (
    <div className="pt-6 pb-20">
      <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumb
            paths={[
              {
                name: "Categories",
                route: "/categories",
                asLink: false,
              },
            ]}
          />
          <div className="lg:flex lg:items-center">
            <div className="lg:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Categories
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the categories in Mintpay
              </p>
            </div>
            <div className="mt-4 lg:mt-0 lg:ml-16 lg:flex-none">
              <Link to={"/categories/add"}>
                <PrimaryButton
                  title="Add Categires"
                  icon={<PlusIcon className="w-5 h-5" />}
                />
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-1/2 xl:w-1/3 mt-5">
            <div>
              <div className="relative flex items-center mt-1">
                <input
                  onChange={handleSearch}
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search with category name"
                  className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-primary-teal focus:border-primary-teal sm:text-sm"
                />
                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                  <kbd
                    className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
                    ⌘K
                  </kbd>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {loading && (
                        <>
                          <tr>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <Skeleton className="w-10 h-10 rounded-md" />
                                  {/* <div className="w-10 h-10 rounded-md bg-primary-teal" /> */}
                                </div>
                                <div className="ml-4">
                                  <Skeleton height={15} width={200} />
                                  {/* <div className="text-gray-500">
                                  {person.email}
                                </div> */}
                                </div>
                              </div>
                            </td>

                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <Skeleton height={15} width={50} />
                            </td>

                            <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                              <Skeleton height={15} width={80} />
                            </td>
                          </tr>
                          <tr>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  <Skeleton className="w-10 h-10 rounded-md" />
                                  {/* <div className="w-10 h-10 rounded-md bg-primary-teal" /> */}
                                </div>
                                <div className="ml-4">
                                  <Skeleton height={15} width={200} />
                                  {/* <div className="text-gray-500">
                                  {person.email}
                                </div> */}
                                </div>
                              </div>
                            </td>

                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              <Skeleton height={15} width={50} />
                            </td>

                            <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                              <Skeleton height={15} width={80} />
                            </td>
                          </tr>
                        </>
                      )}

                      {/* {!loading &&
                        filteredStores.length === 0 &&
                        stores.length === 0 && (
                          <tr>
                            <td colSpan={3}>
                              <div className="flex flex-col items-center py-8 text-center">
                                <ShoppingBagIcon className="w-8 h-8 text-primary-teal " />
                                <h3 className="mt-2 text-sm font-medium text-primary-blue">
                                  No Stores
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                  Get started by creating a new category or search
                                  with different name.
                                </p>
                              </div>
                            </td>
                          </tr>
                        )} */}

                      {!loading &&
                        filteredCategories.map((category) => (
                          <tr key={category.slug}>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <div className="flex items-center">
                                {/* <div className="flex-shrink-0 w-10 h-10">
                                    <img
                                      className="w-10 h-10 rounded-md"
                                      src={`${process.env.REACT_APP_STORES_ASSETS}/${category.cover}`}
                                      alt={category.name}
                                    />
                                  </div> */}
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">
                                    {category.name}
                                  </div>
                                  {/* <div className="text-gray-500">
                                  {person.email}
                                </div> */}
                                </div>
                              </div>
                            </td>

                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              {category.active ? (
                                <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                  Active
                                </span>
                              ) : (
                                <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
                                  Inactive
                                </span>
                              )}
                            </td>

                            <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                              <Link
                                className="text-primary-blue hover:text-primary-blue-dark"
                                to={`/categories/${category.slug}`}
                              >
                                View
                              </Link>
                              <Link
                                to={`/categories/edit/${category.slug}`}
                                className="text-primary-blue hover:text-primary-blue-dark"
                              >
                                Edit
                                {/* <span className="sr-only">, {person.name}</span> */}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      {!loading &&
                        filteredCategories.length === 0 &&
                        categories.map((category) => (
                          <tr key={category.slug}>
                            <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
                              <div className="flex items-center">
                                {/* <div className="flex-shrink-0 w-10 h-10">
                                    <img
                                      className="w-10 h-10 rounded-md"
                                      src={`${process.env.REACT_APP_STORES_ASSETS}/${category.cover}`}
                                      alt={category.name}
                                    />
                                  </div> */}
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">
                                    {category.name}
                                  </div>
                                  {/* <div className="text-gray-500">
                                  {person.email}
                                </div> */}
                                </div>
                              </div>
                            </td>

                            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                              {category.active ? (
                                <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                  Active
                                </span>
                              ) : (
                                <span className="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">
                                  Inactive
                                </span>
                              )}
                            </td>

                            <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                              <Link
                                className="text-primary-blue hover:text-primary-blue-dark"
                                to={`/categories/${category.slug}`}
                              >
                                View
                              </Link>
                              <Link
                                to={`/categories/edit/${category.slug}`}
                                className="text-primary-blue hover:text-primary-blue-dark"
                              >
                                Edit
                                {/* <span className="sr-only">, {person.name}</span> */}
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProtected(CategoriesPage);
