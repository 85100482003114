import React, { Fragment, useEffect, useState } from "react"
import { getAnalyticsForStore } from "../../../../../data/analytics_data"
import { getStores } from "../../../../../data/stores.data"
import { Store } from "../../../../../types"
import {
  ChartBarIcon,
  CheckIcon,
  ChevronDownIcon,
  CogIcon,
} from "@heroicons/react/outline"
import { Combobox, Disclosure, Transition } from "@headlessui/react"
import Skeleton from "react-loading-skeleton"
import SingleStoreChart from "./SingleStoreChart"
import DeviceSelect from "../FilterComponents/DeviceSelect"
import StoreDropDown from "../../../../../core/components/DropDown/StoreDropDown"
import toast from "react-hot-toast"
import DateRangePickerComponent from "../../../../../core/components/DateRangePicker/DateRangePicker"

const ChartWrapper = () => {
  const [loadingAnalytics, setLoadingAnalytics] = useState(true)
  const [isStoreAnalyticsPresent, setIsStoreAnalyticsPresent] = useState(false)
  const [selectedStore, setSelectedStore] = useState<Store | null>(null)
  const [AnalyticData, setAnalyticData] = useState({})
  const [isStoresPopulated, setIsStoresPopulated] = useState<boolean>(false)

  useEffect(() => {
    setLoadingAnalytics(true)
    setIsStoreAnalyticsPresent(false)
    if (selectedStore) {
      getAnalyticsForStore(selectedStore.slug)
        .then((res) => {
          setLoadingAnalytics(false)
          setIsStoreAnalyticsPresent(true)
          setAnalyticData(res)
          toast.success("Analytics Received")
        })
        .catch(() => {
          setLoadingAnalytics(false)
          setIsStoreAnalyticsPresent(false)
          setAnalyticData({})
          toast.error("No Analytics Present")
        })
    }
  }, [selectedStore])

  const [deviceSelection, setDeviceSelection] = useState<number[]>([
    1, 2, 3, 4, 5, 6,
  ])
  const [deviceSelectionString, setDeviceSelectionString] = useState<string>(
    [] as any
  )

  useEffect(() => {
    if (deviceSelection.length === 0) {
      setDeviceSelectionString("none")
    } else if (deviceSelection.length === 6) {
      setDeviceSelectionString("Android, iOS, Windows, Mac, MobileApp, other")
    } else {
      const deviceString = deviceSelection.map((device) => {
        switch (device) {
          case 1:
            return "Android"
          case 2:
            return "iOS"
          case 3:
            return "Windows"
          case 4:
            return "Mac"
          case 5:
            return "MobileApp"
          case 6:
            return "other"
          default:
            return "other"
        }
      })
      setDeviceSelectionString(deviceString.join(", "))
    }
  }, [deviceSelection])

  const [date1, setDate1] = useState(new Date())
  const [date2, setDate2] = useState(new Date())

  const [needsDateFilter, setNeedsDateFilter] = useState(false)
  const [startedSelecting, setStartedSelecting] = useState(false)
  const [selectionDone, setSelectionDone] = useState(false)
  useEffect(() => {
    setStartedSelecting(true)
    if (startedSelecting) {
      setSelectionDone(true)
      if (selectionDone) {
        rangeDone()
      }
    }
  }, [date1, date2])

  const rangeDone = () => {
    setNeedsDateFilter(true)
  }

  return (
    <div className="px-4 sm:px-6 md:px-8 mx-auto max-w-7xl ">
      <div className="relative px-4 py-6  bg-white rounded-lg shadow sm:pt-6 sm:px-6 ">
        <>
          <Disclosure>
            {({ open }) => (
              <>
                <div className="flex w-full justify-between rounded-lg   text-left text-sm font-medium  ">
                  <div className={`w-full flex justify-between`}>
                    <div>
                      <div className="absolute p-3 rounded-md bg-primary-blue-dark">
                        <ChartBarIcon
                          className="w-6 h-6 text-primary-teal"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Store Analytics for
                        <div className="flex items-baseline pb-6 sm:pb-7">
                          {isStoresPopulated ? (
                            <p className="text-2xl font-semibold text-gray-900">
                              {selectedStore?.name}
                            </p>
                          ) : (
                            <Skeleton width={50} height={25} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`pr-4 z-30`}>
                      <StoreDropDown
                        setIsStoresPopulated={setIsStoresPopulated}
                        selectedStore={selectedStore}
                        setSelectedStore={setSelectedStore}
                      />
                    </div>
                  </div>
                  <Disclosure.Button>
                    <div className={`h-full  mt-2`}>
                      <CogIcon
                        className={`${
                          open ? "-rotate-12 transform" : "rotate-12"
                        } h-7 w-7 text-primary-blue`}
                      />
                    </div>
                  </Disclosure.Button>
                </div>
                <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500  relative bottom-5">
                  <div className={`flex flex-col lg:flex-row justify-between`}>
                    <div className="w-4/7 lg:w-1/3">
                      <DeviceSelect
                        deviceSelection={deviceSelection}
                        setDeviceSelection={setDeviceSelection}
                      />
                    </div>
                    <div className="mt-9">
                      <DateRangePickerComponent
                        date1={date1}
                        date2={date2}
                        setDate1={setDate1}
                        setDate2={setDate2}
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <div>
            {!loadingAnalytics && (
              <>
                {isStoreAnalyticsPresent ? (
                  <SingleStoreChart
                    data={AnalyticData}
                    deviceSelectionString={deviceSelectionString}
                    needsDateFilter={needsDateFilter}
                    setNeedsDateFilter={setNeedsDateFilter}
                    startDate={date1}
                    endDate={date2}
                  />
                ) : (
                  <div className="flex justify-center items-center">
                    <p className="text-2xl font-semibold text-gray-900">
                      No Analytics Found
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      </div>
    </div>
  )
}

export default ChartWrapper
