import { useState } from "react"
import { DateRange } from "react-date-range"

import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"

interface DateRangePickerProps {
  date1: Date
  date2: Date
  setDate1: (date: Date) => void
  setDate2: (date: Date) => void
}

const DateRangePicker = ({
  date1,
  date2,
  setDate1,
  setDate2,
}: DateRangePickerProps) => {
  const handleSelect = (ranges: any) => {
    setDate1(ranges.selection.startDate)
    setDate2(ranges.selection.endDate)
  }
  const selectionRange = {
    startDate: date1,
    endDate: date2,
    key: "selection",
  }

  return (
    <div className="calendarWrap">
      <DateRange
        ranges={[selectionRange]}
        onChange={handleSelect}
        className={`calendarWrap`}
        rangeColors={["#004371"]}
        maxDate={new Date()}
      />
    </div>
  )
}

export default DateRangePicker
