import React, { useEffect, useState } from "react"
import { ScheduledBanner } from "../../../types"


interface BannerListDragDropProps {
  data?: ScheduledBanner[]
  loading: boolean
}

const ScheduledBannersList = ({
                                data = [],
                                loading,
                              }: BannerListDragDropProps) => {
  const [bannerList, setBannerList] = React.useState<ScheduledBanner[]>(data)

  useEffect(() => {
    setBannerList(data)
  }, [data])

  const removeBannerFromTheList = (bannerSlug: string) => {
    const newBannerList = bannerList.filter(
      (banner) => banner.slug !== bannerSlug,
    )

    setBannerList(newBannerList)
  }

  const removeScheduledBanner = (bannerSlug: string) => {


  }


  return (
    <div className="block">
      <div className="mx-auto max-w-6xl">
        <div className="mt-2 flex flex-col">
          <div className="min-w-full overflow-hidden overflow-x-auto align-middle sm:rounded-lg">
            <div className="min-w-full ">
              {!loading && bannerList.length > 0 &&
                <div
                  className=" pr-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Scheduled Banners
                </div>
              }
              {!loading &&
                bannerList && bannerList.map((singleBanner) => (
                  <div
                    key={`${singleBanner.slug + singleBanner.endTime} `}
                    className="flex flex-col mt-2 xl:flex-row xl:items-center rounded-md xl:h-18 relative border justify-between"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail})`,
                    }}
                  >
                    <div className="flex flex-row ">
                      <div className="flex flex-row w-auto">
                        <img
                          className="h-14 "
                          src={`${process.env.REACT_APP_STORES_ASSETS}/${singleBanner.tabletThumbnail}`}
                          alt=""
                        />
                        <h3 className={`my-auto pl-3`}>
                          {singleBanner.title}
                        </h3>
                      </div>
                    </div>
                    <div className={"flex flex-row"}>
                      <div className={"grid grid-cols-3 text-sm font-semibold text-left text-gray-900"}>
                        <span className={"text-left "}>Start Date:</span>
                        <div className={"col-span-2 pl-2"}>
                          {singleBanner.startTime ? <>
                            <span>{singleBanner.startTime.split("T")[0]}</span>
                            <span
                              className={"pl-2"}>{singleBanner.startTime.split("T")[1].slice(0, 5)} {parseInt(singleBanner.startTime.split("T")[1].slice(11, 13)) > 12 ? "PM" : "AM"}</span>
                          </> : <>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18"
                                 viewBox="0 0 48 48">
                              <path fill="#c8e6c9"
                                    d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                              <path fill="#4caf50"
                                    d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                            </svg>
                          </>}
                        </div>
                        <span className={"text-left"}>End Date:</span>
                        <div className={"col-span-2 pl-2"}>
                          {singleBanner.endTime ? <>
                              <span>{singleBanner.endTime.split("T")[0]}</span>
                              <span
                                className={"pl-2"}>{singleBanner.endTime.split("T")[1].slice(0, 5)} {parseInt(singleBanner.endTime.split("T")[1].slice(11, 13)) > 12 ? "PM" : "AM"}</span>
                            </>
                            : <>
                              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18"
                                   viewBox="0 0 48 48">
                                <path fill="#c8e6c9"
                                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                                <path fill="#4caf50"
                                      d="M34.586,14.586l-13.57,13.586l-5.602-5.586l-2.828,2.828l8.434,8.414l16.395-16.414L34.586,14.586z"></path>
                              </svg>
                            </>
                          }
                        </div>
                      </div>
                      <button
                        onClick={() => removeBannerFromTheList(singleBanner.slug)}
                        className={"px-4"}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M8 9.05L10.175 11.225C10.3125 11.3625 10.4875 11.4312 10.7 11.4312C10.9125 11.4312 11.0875 11.3625 11.225 11.225C11.3625 11.0875 11.4312 10.9125 11.4312 10.7C11.4312 10.4875 11.3625 10.3125 11.225 10.175L9.05 8L11.225 5.825C11.3625 5.6875 11.4312 5.5125 11.4312 5.3C11.4312 5.0875 11.3625 4.9125 11.225 4.775C11.0875 4.6375 10.9125 4.56875 10.7 4.56875C10.4875 4.56875 10.3125 4.6375 10.175 4.775L8 6.95L5.825 4.775C5.6875 4.6375 5.5125 4.56875 5.3 4.56875C5.0875 4.56875 4.9125 4.6375 4.775 4.775C4.6375 4.9125 4.56875 5.0875 4.56875 5.3C4.56875 5.5125 4.6375 5.6875 4.775 5.825L6.95 8L4.775 10.175C4.6375 10.3125 4.56875 10.4875 4.56875 10.7C4.56875 10.9125 4.6375 11.0875 4.775 11.225C4.9125 11.3625 5.0875 11.4312 5.3 11.4312C5.5125 11.4312 5.6875 11.3625 5.825 11.225L8 9.05ZM8 15.5C6.9625 15.5 5.9875 15.3031 5.075 14.9094C4.1625 14.5156 3.36875 13.9813 2.69375 13.3063C2.01875 12.6313 1.48438 11.8375 1.09063 10.925C0.696875 10.0125 0.5 9.0375 0.5 8C0.5 6.9625 0.696875 5.9875 1.09063 5.075C1.48438 4.1625 2.01875 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48438 5.075 1.09063C5.9875 0.696875 6.9625 0.5 8 0.5C9.0375 0.5 10.0125 0.696875 10.925 1.09063C11.8375 1.48438 12.6313 2.01875 13.3063 2.69375C13.9813 3.36875 14.5156 4.1625 14.9094 5.075C15.3031 5.9875 15.5 6.9625 15.5 8C15.5 9.0375 15.3031 10.0125 14.9094 10.925C14.5156 11.8375 13.9813 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5156 10.925 14.9094C10.0125 15.3031 9.0375 15.5 8 15.5Z"
                            fill="#F46363" />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduledBannersList
