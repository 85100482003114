import { Category, Keyword, Store, UploadItem } from "../types"
import axios from "../utils/axios"
import BaseResponse from "./base_response"

interface GetAllStores extends BaseResponse {
  data?: {
    total: number
    lastKey: any
    count: number
    stores: Store[]
  }
}

interface GetStats extends BaseResponse {
  data?: {
    storesCount?: number
  }
}

interface SearchResponse extends BaseResponse {
  data?: {
    stores?: Store[]
    categories?: Category[]
    keywords?: Keyword[]
  }
}

interface CategoryStores extends BaseResponse {
  data?: {
    category: Category
    result: {
      count: number
      stores: Store[]
    }
  }
}

interface GetStore extends BaseResponse {
  data?: Store
}

const searchStoresAndCategories = async (
  limit?: number,
  startKey?: any,
  query?: string,
  only?: "stores" | "categories" | "keywords"
) => {
  const params = {
    limit,
    startKey,
    query,
    only,
  }

  return axios.get<SearchResponse>(`/api/search`, {
    params,
  })
}

const getStoreByIndex = async (index: number): Promise<Store | undefined> => {
  const response = await axios.get<GetStore>(`/api/storeByIndex/${index}`)

  if (response.status === 200) {
    return response.data.data!
  } else {
    return undefined
  }
}

const getStats = async (): Promise<GetStats | undefined> => {
  const response = await axios.get<GetStats>("/api/stats")

  if (response.status === 200 && response.data) {
    return response.data
  }
}

const getStore = async (slug: string): Promise<Store | undefined> => {
  const response = await axios.get<GetStore>(`/api/stores/${slug}`)

  if (response.status === 200 && response.data.data) {
    return response.data.data
  }
}

const getStoresByCategory = async (
  slug: string
): Promise<CategoryStores | undefined> => {
  const response = await axios.get<CategoryStores>(
    `/api/storesByCategory/${slug}`
  )

  if (response.status === 200 && response.data.data) {
    return response.data
  }
}

const removeOldNewArrivalStores = async () => {
  const response = await axios.delete(
    "/api/categoryStores/removeOldNewArrivalStores"
  )

  if (
    (response.status === 200 || response.status === 202) &&
    response.data.success
  ) {
    return response
  }

  throw new Error(`Remove Oldest New Arrival Failed!`)
}

const getKeywordsFromUrl = async (
  storeSlug: string,
  storeURL: string,
  fullSearch: boolean
) => {
  const response = await axios.get("/api/keywords/getKeywordsOfWebsite", {
    params: {
      storeSlug: storeSlug?.trim(),
      storeURL: storeURL?.trim(),
      fullSearch: fullSearch,
    },
  })

  let groupedKeywordArray = []

  if (
    (response.status === 200 || response.status === 202) &&
    response.data.success
  ) {
    groupedKeywordArray = response.data.data.keywords

    return {
      keywords: groupedKeywordArray,
    }
  }
  throw new Error(`Getting Keywords from the store failed!`)
}

const getAllKeywords = async () => {
  const response = await axios.get("/api/keywords/getAll")

  if (response.status === 200 && response.data.success) {
    return response.data.data.keywords
  }

  throw new Error(`Getting Keywords failed!`)
}

const createKeyword = async (
  keywordSlug: string,
  KeywordName: string,
  description: string | null = null
) => {
  const response = await axios.post<BaseResponse>("/api/keywords/create", {
    slug: keywordSlug,
    name: KeywordName,
    description: description,
  })

  if (response.status === 201 && response.data.success) {
    return response
  }

  throw new Error(`Create Keyword Failed!`)
}

const createKeywordStores = async (
  storeSlug: string,
  keywordSlug: string,
  KeywordName: string,
  description: string | null = null
) => {
  const response = await axios.post<BaseResponse>("/api/keywordStores/create", {
    storeSlug,
    keywordSlug,
    KeywordName,
    description,
  })

  if (response.status === 200 && response.data.success) {
    return response
  }

  throw new Error(`Create Keyword Store Failed!`)
}

const getKeywordStoresBySlug = async (storeSlug: string) => {
  const response = await axios.get("/api/keywordStores/get", {
    params: {
      storeSlug: storeSlug?.trim(),
    },
  })

  if (response.status === 200 && response.data.success) {
    return response.data.data
  }

  throw new Error(`Getting Keyword Stores failed!`)
}

const removeKeywordStore = async (storeSlug: string, keywordSlug: string) => {
  const response = await axios.delete<BaseResponse>(
    `/api/keywordStores/remove`,
    {
      params: {
        storeSlug,
        keywordSlug,
      },
    }
  )
  if (response.status === 200 && response.data.success) {
    return response
  }

  throw new Error(`Remove Keyword Store Failed!`)
}

const getStores = async (
  limit?: number,
  startKey?: any,
  query?: string
): Promise<GetAllStores | undefined> => {
  const params = {
    limit,
    startKey,
    query,
  }

  const response = await axios.get<GetAllStores>("/api/stores", {
    params,
  })

  if (response.status === 200 && response.data.data) {
    return response.data
  }
}

const reorderStores = async (stores: any[]) => {
  const response = await axios.post<BaseResponse>("/api/stores/reOrder", {
    stores,
  })

  return response
}

const editStore = async (slug: string, data: { [key: string]: any }) => {
  try {
    const body = {
      slug: slug,
      ...data,
    }

    var response = await axios.post<BaseResponse>("/api/stores/edit", body)

    if (response.status === 200 && response.data.success) {
      return response
    } else {
      throw Error(response.data.message ?? "Error occured!")
    }
  } catch (error) {
    console.log(error)
    throw Error(`Edit Store Failed!`)
  }
}

const createStore = async (data: any) => {
  try {
    var response = await axios.post<BaseResponse>("/api/stores/create", data)

    if (response.status === 201 && response.data.success) {
      return response
    } else {
      throw Error(response.data.message ?? "Error occured!")
    }
  } catch (error) {
    console.log(error)
    throw Error(`Create Store Failed!`)
  }
}

const uploadImage = async (item: UploadItem) => {
  var body = { key: item.key, action: "putObject" }
  try {
    var response = await axios.post("/api/getPresignedUrl", body)

    if (response.status === 200) {
      var _body = response.data
      var url = _body["data"]["url"]

      console.log(`1. Get URL Success: ${item.key}`)

      const headers = {
        "Content-Type": item.file.type,
      }

      const upload = await fetch(url, {
        method: "PUT",
        body: item.file,
        headers: headers,
      })

      if (upload.status === 200) {
        console.log(`2. Upload Success: ${item.key}`)
        return
      }

      throw new Error(`Upload Error`)
    }
  } catch (error) {
    console.log(error)
    throw Error(`Upload Failed: Key: ${item.key}`)
  }
}

const slugifyWord = (
  word: string,
  searchValue: string | RegExp = /\s\s+/g,
  replaceValue: string = " ",
  joinValue: string = "-"
) => {
  return word
    ?.toString()
    .trim()
    .replace(searchValue, replaceValue)
    .split(replaceValue)
    .join(joinValue)
    .toLowerCase()
    .replace("%", "percent")
}

export {
  getStores,
  reorderStores,
  uploadImage,
  createStore,
  editStore,
  removeOldNewArrivalStores,
  getStore,
  getStoresByCategory,
  searchStoresAndCategories,
  getStoreByIndex,
  getStats,
  getKeywordsFromUrl,
  slugifyWord,
  createKeyword,
  createKeywordStores,
  getKeywordStoresBySlug,
  removeKeywordStore,
  getAllKeywords,
}
