import React, { useState } from "react"
import { MantineProvider, Modal, Popover } from "@mantine/core"
import CategoryDateTimeScheduler from "../../../modules/categories/components/CategoryDateTimeScheduler"
import dayjs from "dayjs"
import { scheduleBanner } from "../../../data/banner_data"
import toast from "react-hot-toast"


interface BannerPlaceModalProps {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  image:string
  bannerSlug: string
  bannerPlace:string
  categorySlug:string
}

const SchedulerInputModal: React.FC<BannerPlaceModalProps> = ({
                                                                isOpened,
                                                                setIsOpened,
                                                                image,
                                                                bannerSlug,
                                                                bannerPlace,
                                                                categorySlug,
                                                              }) => {



  const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>([null, null])

  const [uploadingTimeSlot, setUploadingTimeSlot] = useState("")

  const [removingTimeSlot, setRemovingTimeSlot] = useState("")

  const [clear, setClear] = useState(false)

  const [displaySelectedDateTimeRange, setDisplaySelectedDateTimeRange] = useState("")

  const convertToUTC = (date: Date | null, time: string): string | null => {
    if (!date || !time) return null;


    const [hours, minutes] = time.split(":").map(Number);

    // Set the date's time according to the given hours and minutes
    const combinedDate = new Date(date);
    combinedDate.setHours(hours, minutes);

    // Format the date to remove the milliseconds and 'Z' at the end
    const year = combinedDate.getFullYear();
    const month = String(combinedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(combinedDate.getDate()).padStart(2, '0');
    const hour = String(combinedDate.getHours()).padStart(2, '0');
    const minute = String(combinedDate.getMinutes()).padStart(2, '0');
    const second = String(combinedDate.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
  };

  const handleScheduleBanner = async () => {
    const uploadingDate = dayjs(selectedDateRange[0]).format("DD/MM/YYYY")
    const removingDate = dayjs(selectedDateRange[1]).format("DD/MM/YYYY")
    const utcStartDateTime = convertToUTC(selectedDateRange[0], uploadingTimeSlot);
    const utcEndDateTime = convertToUTC(selectedDateRange[1], removingTimeSlot);

    if (selectedDateRange[0] && selectedDateRange[1] && uploadingTimeSlot && removingTimeSlot) {
      try {
        await scheduleBanner('BannerSchedule',utcStartDateTime!,utcEndDateTime!,'BannerSchedule',bannerSlug,bannerPlace,categorySlug);
        toast.success("banner scheduled Successfully!");
        setDisplaySelectedDateTimeRange(`${uploadingDate} ${uploadingTimeSlot} - ${removingDate} ${removingTimeSlot}`)
        setIsOpened(false)
        setClear(true)
      } catch (error:any) {
        toast.error(`${error.response.data}`);
        setClear(true)
        setIsOpened(false)
      }
    }

  };


  return (
    <Modal
      className={"p-0 w-full"}
      opened={isOpened}
      onClose={() => setIsOpened(false)}
      title={<div className="text-lg font-semibold text-gray-900">Schedule Banner</div>}
      top={12}
      closeButtonProps={{
        className: 'text-gray-900 ',
        style: { width: '14px', height: '14px' },
      }}
      styles={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius:8,
        },
        body: {
          padding: 0,
        }
      }}
    >
      <div className="flex flex-col w-full ">
        <div
          className="p-4 pt-0"
        >
          <img
            className={`mx-auto w-[320px] h-[192px] `}
            src={`https://d13suhwb0mb1uc.cloudfront.net/${image}`}
            alt="promotion image banner"
          />
        </div>
        <div className={'flex flex-row p-4 space-x-4'}>
            <span className={'text-base text-gray-900'}>Schedule date & time</span>
            <CategoryDateTimeScheduler remove={clear} onSelect={(dateRange:[Date | null, Date | null],uploadTime:string,removeTime:string) =>{
              setSelectedDateRange(dateRange)
              setUploadingTimeSlot(uploadTime)
              setRemovingTimeSlot(removeTime)
            }}/>
        </div>
        <div className={"flex flex-row w-full p-4 border border-t-gray-200"}>
          <button
            onClick={() =>{
              setClear(true)
            }}
            className="w-full inline-flex leading-none text-primary-blue justify-center rounded-lg border border-slate-200 shadow-sm px-auto py-4 bg-white text-base font-medium   sm:text-sm"
          >
            Cancel
          </button>
          <button
            onClick={handleScheduleBanner}
            className="w-full inline-flex leading-none bg-primary-blue justify-center rounded-lg shadow-sm px-auto py-4 text-base font-medium text-white sm:ml-3 sm:text-sm"
          >
            Done
          </button>

        </div>
      </div>
    </Modal>
  )
}
export default SchedulerInputModal