import React, { useEffect } from "react"

const devices = [
  { id: 1, name: "Android" },
  { id: 2, name: "iOS" },
  { id: 3, name: "Windows" },
  { id: 4, name: "Mac" },
  { id: 5, name: "Mobile App" },
  { id: 6, name: "Other" },
]

interface DeviceSelectProps {
  setDeviceSelection: (deviceSelection: number[]) => void
  deviceSelection: number[]
}

const DeviceSelect = ({
  setDeviceSelection,
  deviceSelection,
}: DeviceSelectProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target
    const idNum = parseInt(id.split("-")[1])

    if (checked) {
      setDeviceSelection([...deviceSelection, idNum].sort((a, b) => a - b))
    } else {
      setDeviceSelection(
        deviceSelection.filter((id) => id !== idNum).sort((a, b) => a - b)
      )
    }
  }

  useEffect(() => {
    setDeviceSelection(deviceSelection)
  }, [deviceSelection])

  return (
    <fieldset>
      <legend className="text-lg font-medium text-gray-900">Device</legend>
      <div className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200">
        {devices.map((singleDevice, deviceIdx) => (
          <div key={deviceIdx} className="relative flex items-start py-4">
            <div className="min-w-0 flex-1 text-sm">
              <label
                htmlFor={`device-${singleDevice.id}`}
                className="select-none font-medium text-gray-700"
              >
                {singleDevice.name}
              </label>
            </div>
            <div className="ml-3 flex h-5 items-center">
              <input
                checked={deviceSelection.includes(singleDevice.id)}
                onChange={handleChange}
                id={`device-${singleDevice.id}`}
                name={`device-${singleDevice.id}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-blue focus:ring-primary-blue"
              />
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export default DeviceSelect
