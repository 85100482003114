import classNames from 'classnames'
import { FC } from 'react'

interface PrimaryButtonProps {
  icon?: JSX.Element;
  title: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  className?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
                                                 icon,
                                                 title,
                                                 type,
                                                 disabled = false,
                                                 loading = false,
                                                 onClick,
                                                 className,
                                               }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type ?? 'button'}
      className={classNames(
        disabled && 'cursor-not-allowed',
        ` inline-flex items-center justify-center px-4 py-2 space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-md shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto ${className}`,
      )}
    >
      {loading ? (
        <>
          <svg
            className='w-5 h-5 mr-3 -ml-1 text-white animate-spin'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx={12}
              cy={12}
              r={10}
              stroke='currentColor'
              strokeWidth={4}
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        </>
      ) : (
        icon
      )}
      <span>{title}</span>
    </button>
  )
}

export default PrimaryButton
