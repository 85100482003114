import { Dialog, Disclosure, Transition } from "@headlessui/react"
import classNames from "classnames"
import { FC, Fragment, useState } from "react"

import {
  HomeIcon,
  XIcon,
  ShoppingBagIcon,
  CollectionIcon,
  ChevronUpIcon,
  NewspaperIcon,
  BookmarkIcon,
  KeyIcon,
} from "@heroicons/react/outline"
import { Link, useLocation } from "react-router-dom"

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  {
    name: "Stores",
    href: "/stores",
    icon: ShoppingBagIcon,
    subRoutes: [
      { name: "Add Store", href: "/stores/add" },
      { name: "List Stores", href: "/stores" },
      // { name: "Reorder Stores", href: "/stores/reorder" },
    ],
  },
  {
    name: "Categories",
    href: "/categories",
    icon: CollectionIcon,
    current: false,
    subRoutes: [
      { name: "Add Category", href: "/categories/add" },
      { name: "List Categories", href: "/categories" },
      { name: "Reorder Categories", href: "/categories/reorder" },
    ],
  },
  {
    name: "Press",
    href: "/press",
    icon: NewspaperIcon,
    current: false,
    subRoutes: [
      { name: "Add Press", href: "/press/add" },
      { name: "List Press", href: "/press" },
    ],
  },
  {
    name: "Banners",
    href: "/banners",
    icon: BookmarkIcon,
    current: false,
    subRoutes: [
      { name: "Add Banner", href: "/banners/add" },
      { name: "List Banners", href: "/banners" },
    ],
  },
  {
    name: "Keywords",
    href: "/keywords",
    icon: KeyIcon,
    current: false,
  },
]

const SideBar: FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const location = useLocation()

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-primary-blue-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex items-center flex-shrink-0 px-4">
                  <img
                    className="w-auto h-8"
                    src="/logo.png"
                    alt="Mintpay Admin"
                  />
                </div>
                <div className="flex-1 h-0 mt-5 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base  rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-primary-teal"
                              : "text-primary-teal group-hover:text-primary-teal",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-1 min-h-0 bg-primary-blue-dark">
            <div className="flex items-center justify-center flex-shrink-0 h-20 px-4 bg-primary-blue-dark">
              <img
                className=" w-[150px]"
                src="/logo-horizontal.png"
                alt="Mintpay Admin"
              />
            </div>
            <div className="flex flex-col flex-1 overflow-y-auto">
              <nav className="flex-1 px-2 py-4 space-y-1">
                {navigation.map((item) => {
                  const isCurrent = item.href === location.pathname

                  if (item.subRoutes) {
                    const active =
                      item.href.split("/")[1] ===
                      location.pathname.split("/")[1]

                    return (
                      <div key={item.name}>
                        <Disclosure defaultOpen={true}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  active
                                    ? "bg-primary-blue  text-white"
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "group flex w-full justify-between items-center px-2 py-2 text-sm  rounded-md"
                                )}
                              >
                                <div className="flex items-center">
                                  <item.icon
                                    className={classNames(
                                      active
                                        ? "text-primary-teal"
                                        : "text-primary-teal group-hover:text-primary-teal",
                                      "mr-3 flex-shrink-0  h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </div>
                                <ChevronUpIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 transition-all duration-200 text-primary-teal`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                {item.subRoutes.map((subItem) => {
                                  const isCurrent =
                                    subItem.href === location.pathname
                                  return (
                                    <div
                                      className="flex items-center w-full "
                                      key={subItem.name}
                                    >
                                      <span
                                        className={`h-0.5 bg-primary-teal rounded-full transition-all duration-200 ${
                                          isCurrent ? "w-4" : "w-0"
                                        }`}
                                      ></span>

                                      <Link
                                        key={subItem.name}
                                        to={subItem.href}
                                        className={classNames(
                                          isCurrent
                                            ? "text-primary-teal"
                                            : "text-gray-300  hover:text-white",
                                          "group flex w-full items-center px-2 py-2 text-sm  rounded-md"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    </div>
                                  )
                                })}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    )
                  }

                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        isCurrent
                          ? "bg-primary-blue text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm  rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isCurrent
                            ? "text-primary-teal"
                            : "text-primary-teal group-hover:text-primary-teal",
                          "mr-3 flex-shrink-0  h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  )
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SideBar
