import React from 'react';

interface LastPageIconProps {
    disabled?: boolean;
}

const LastPageIcon: React.FC<LastPageIconProps> = ({ disabled }) => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid={'last-page-button-icon'}
        >
            <g>
                <path
                    d="M8.50016 10.5002L5.25016 7.25024C5.09738 7.09747 5.021 6.90302 5.021 6.66691C5.021 6.4308 5.09738 6.23636 5.25016 6.08358C5.40294 5.9308 5.59738 5.85441 5.8335 5.85441C6.06961 5.85441 6.26405 5.9308 6.41683 6.08358L10.2502 9.91691C10.3335 10.0002 10.3925 10.0905 10.4272 10.1877C10.462 10.285 10.4793 10.3891 10.4793 10.5002C10.4793 10.6114 10.462 10.7155 10.4272 10.8127C10.3925 10.91 10.3335 11.0002 10.2502 11.0836L6.41683 14.9169C6.26405 15.0697 6.06961 15.1461 5.8335 15.1461C5.59738 15.1461 5.40294 15.0697 5.25016 14.9169C5.09738 14.7641 5.021 14.5697 5.021 14.3336C5.021 14.0975 5.09738 13.903 5.25016 13.7502L8.50016 10.5002ZM14.1668 5.50024C14.4029 5.50024 14.6009 5.58011 14.7606 5.73983C14.9203 5.89955 15.0002 6.09747 15.0002 6.33358V14.6669C15.0002 14.903 14.9203 15.1009 14.7606 15.2607C14.6009 15.4204 14.4029 15.5002 14.1668 15.5002C13.9307 15.5002 13.7328 15.4204 13.5731 15.2607C13.4134 15.1009 13.3335 14.903 13.3335 14.6669V6.33358C13.3335 6.09747 13.4134 5.89955 13.5731 5.73983C13.7328 5.58011 13.9307 5.50024 14.1668 5.50024Z"
                    fill={disabled ? '#D5DBE1' : '#1C1B1F'}
                />
            </g>
        </svg>
    );
};

export default LastPageIcon;
