import React from 'react'

interface BannerButtonProps {
  textColor: string
  backgroundColor: string
  buttonText: string
}

const BannerButton = ({
  textColor,
  backgroundColor,
  buttonText,
}: BannerButtonProps) => {

  return (
    <button
      style={{
        color: textColor,
        backgroundColor: backgroundColor,
      }}
      className="font-poppins font-semibold no-underline text-center justify-center flex items-center rounded-full px-5 py-2"
    >
      {buttonText}
    </button>
  )
}

export default BannerButton
