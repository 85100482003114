import React from "react"
import {  Modal  } from "@mantine/core"


interface BannerPlaceModalProps {
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
  ActivateNowHandler: () => void;
  scheduleHandler: () => void;
  category:string
  image:string
}

const BannerPlaceModal: React.FC<BannerPlaceModalProps> = ({
                                                             isOpened,
                                                             setIsOpened,
                                                             ActivateNowHandler,
                                                             scheduleHandler,
                                                             category,
                                                             image,
                                                                       }) => {


return (
  <Modal
    className={"p-0"}
    opened={isOpened}
    onClose={() => setIsOpened(false)}
    title={<div className="text-lg font-semibold text-gray-900">Banner Actions</div>}
    centered
    closeButtonProps={{
      className: 'text-gray-900 ',
      style: { width: '14px', height: '14px' },
    }}
    styles={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          borderRadius:8,
        },
        body: {
          padding: 0,
        }
    }}
  >
    <div className="flex flex-col ">
      <div className={'w-full p-4 border-t border-t-gray-200'}>
        <span className={'text-base text-gray-900'}>Select your option to place the banner to </span>
        <span className={'text-base font-semibold text-gray-900'}>{category}</span>
      </div>
      <div
        className="p-4 pt-0"
      >
        <img
          className={`mx-auto w-[320px] h-[192px] `}
          src={`https://d13suhwb0mb1uc.cloudfront.net/${image}`}
          alt="promotion image banner"
        />
      </div>
      <div className={"flex flex-row w-full p-4 border border-t-gray-200"}>
        <button
          onClick={scheduleHandler}
          className="w-full inline-flex leading-none text-primary-blue justify-center rounded-lg border border-slate-200 shadow-sm px-auto py-4 bg-white text-base font-medium   sm:text-sm"
        >
          Schedule later
        </button>
        <button
          onClick={ActivateNowHandler}
          className="w-full inline-flex leading-none bg-primary-blue justify-center rounded-lg shadow-sm px-auto py-4 text-base font-medium text-white sm:ml-3 sm:text-sm"
        >
          Place now
        </button>

      </div>
    </div>
  </Modal>
)
}

export default BannerPlaceModal