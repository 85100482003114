/* eslint-disable jsx-a11y/alt-text */
export const LoadingLayout = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen min-w-full min-h-screen">
      <div className="w-[200px] flex justify-center flex-col items-center">
        <img src={"/loading.gif"} alt="Loading" />
        <p className="pt-2 text-primary-blue">Loading...</p>
      </div>
    </div>
  );
};
