import { createSlice } from "@reduxjs/toolkit"
import { Category, Store } from "../types"

interface StoreWithCategories {
  store: Store,
  categories: Category[],
}

interface StoreItemState {
  storeItemData: StoreWithCategories[],
  loading: boolean;
  error: string | null;
}

const initialState: StoreItemState = {
  storeItemData: [],
  loading: false,
  error: null,
}

const storeSlice = createSlice({
  name: "storeItem",
  initialState,
  reducers: {
    setStoreItemData: (state, action) => {
      const { store, categories } = action.payload

      const itemExists = state.storeItemData.some(item => item.store.slug === store.slug)

      if (!itemExists) {
        state.storeItemData = [...state.storeItemData, { store: store, categories: categories }]
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const { setStoreItemData, setLoading, setError } = storeSlice.actions

export default storeSlice.reducer