import axios from "../utils/axios"
import BaseResponse from "./base_response"
import { Keyword } from "../types"

interface getAllKeywordsResponse extends BaseResponse {
  data: {
    keywords: Keyword[]
  }
}

interface editKeywordParams {
  slug: string
  name: string | null
  description: string | null
  active: boolean | null
}

const autoGenerateKeywords = async (slugArray?: string[]) =>
  axios
    .post<BaseResponse>(`/api/keywordStores/create-by-store-slug`, {
      slugArray: slugArray,
    })
    .then((res) => res.data)

const getAllKeywords = async () =>
  axios
    .get<getAllKeywordsResponse>(`/api/keywords/getAll`)
    .then((res) => res.data)

const editKeyword = async ({
  slug,
  name,
  description,
  active,
}: editKeywordParams) => {
  axios
    .post<BaseResponse>(`/api/keywords/edit`, {
      slug,
      name,
      description,
      active,
    })
    .then((res) => res.data)
}

export { autoGenerateKeywords, getAllKeywords, editKeyword }
