import React from 'react';

interface FirstPageIconProps {
    disabled?: boolean;
}

const FirstPageIcon: React.FC<FirstPageIconProps> = ({ disabled }) => {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid={'first-page-button-icon'}
        >
            <g>
                <path
                    d="M11.4998 10.5002L14.7498 7.25024C14.9026 7.09747 14.979 6.90302 14.979 6.66691C14.979 6.4308 14.9026 6.23636 14.7498 6.08358C14.5971 5.9308 14.4026 5.85441 14.1665 5.85441C13.9304 5.85441 13.7359 5.9308 13.5832 6.08358L9.74984 9.91691C9.6665 10.0002 9.60748 10.0905 9.57275 10.1877C9.53803 10.285 9.52067 10.3891 9.52067 10.5002C9.52067 10.6114 9.53803 10.7155 9.57275 10.8127C9.60748 10.91 9.6665 11.0002 9.74984 11.0836L13.5832 14.9169C13.7359 15.0697 13.9304 15.1461 14.1665 15.1461C14.4026 15.1461 14.5971 15.0697 14.7498 14.9169C14.9026 14.7641 14.979 14.5697 14.979 14.3336C14.979 14.0975 14.9026 13.903 14.7498 13.7502L11.4998 10.5002ZM5.83317 5.50024C5.59706 5.50024 5.39914 5.58011 5.23942 5.73983C5.0797 5.89955 4.99984 6.09747 4.99984 6.33358V14.6669C4.99984 14.903 5.0797 15.1009 5.23942 15.2607C5.39914 15.4204 5.59706 15.5002 5.83317 15.5002C6.06928 15.5002 6.2672 15.4204 6.42692 15.2607C6.58664 15.1009 6.6665 14.903 6.6665 14.6669V6.33358C6.6665 6.09747 6.58664 5.89955 6.42692 5.73983C6.2672 5.58011 6.06928 5.50024 5.83317 5.50024Z"
                    fill={disabled ? '#D5DBE1' : '#1C1B1F'}
                />
            </g>
        </svg>
    );
};

export default FirstPageIcon;
