import { Press } from "../types"
import axios from "../utils/axios"
import BaseResponse from "./base_response"

interface GetAllPress extends BaseResponse {
  data?: Press[]
}

const deletePress = (slug: string) =>
  axios.delete<BaseResponse>(`/press/delete/${slug}`).then((res) => res.data)

const createPress = (data: any) =>
  axios.post<BaseResponse>("/press/add", data).then((res) => res.data)

const getAllPress = () =>
  axios.get<GetAllPress>("/press/all").then((res) => res.data)

export { getAllPress, createPress, deletePress }
