import React from "react"

const PillCloseIcon = () => {
  return (
    <svg viewBox="0 0 16 17" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M7.954 9.42796L9.87621 11.3502C9.99773 11.4717 10.1524 11.5325 10.3402 11.5325C10.528 11.5325 10.6827 11.4717 10.8042 11.3502C10.9257 11.2287 10.9865 11.074 10.9865 10.8862C10.9865 10.6984 10.9257 10.5437 10.8042 10.4222L8.88197 8.5L10.8042 6.57779C10.9257 6.45627 10.9865 6.30161 10.9865 6.11381C10.9865 5.926 10.9257 5.77134 10.8042 5.64982C10.6827 5.5283 10.528 5.46755 10.3402 5.46755C10.1524 5.46755 9.99773 5.5283 9.87621 5.64982L7.954 7.57204L6.03179 5.64982C5.91027 5.5283 5.75561 5.46755 5.56781 5.46755C5.38 5.46755 5.22534 5.5283 5.10382 5.64982C4.98231 5.77134 4.92155 5.926 4.92155 6.11381C4.92155 6.30161 4.98231 6.45627 5.10382 6.57779L7.02604 8.5L5.10382 10.4222C4.98231 10.5437 4.92155 10.6984 4.92155 10.8862C4.92155 11.074 4.98231 11.2287 5.10382 11.3502C5.22534 11.4717 5.38 11.5325 5.56781 11.5325C5.75561 11.5325 5.91027 11.4717 6.03179 11.3502L7.954 9.42796ZM7.954 15.1283C7.03708 15.1283 6.1754 14.9543 5.36896 14.6063C4.56251 14.2583 3.86101 13.7861 3.26447 13.1895C2.66792 12.593 2.19565 11.8915 1.84766 11.085C1.49968 10.2786 1.32568 9.41692 1.32568 8.5C1.32568 7.58308 1.49968 6.7214 1.84766 5.91496C2.19565 5.10851 2.66792 4.40701 3.26447 3.81047C3.86101 3.21392 4.56251 2.74165 5.36896 2.39366C6.1754 2.04568 7.03708 1.87168 7.954 1.87168C8.87092 1.87168 9.7326 2.04568 10.539 2.39366C11.3455 2.74165 12.047 3.21392 12.6435 3.81047C13.2401 4.40701 13.7123 5.10851 14.0603 5.91496C14.4083 6.7214 14.5823 7.58308 14.5823 8.5C14.5823 9.41692 14.4083 10.2786 14.0603 11.085C13.7123 11.8915 13.2401 12.593 12.6435 13.1895C12.047 13.7861 11.3455 14.2583 10.539 14.6063C9.7326 14.9543 8.87092 15.1283 7.954 15.1283Z"
          fill="#FF2F3B" />
      </g>
    </svg>
  )
}

export default PillCloseIcon