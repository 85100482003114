import axios from "../utils/axiosNew"

const storeListData = async (page: number, perPage?: number) => {
  const params = {
    page,
    perPage,
  }

  return await axios.get(`/admin/api/search/stores/paginate`, { params }).then((response) => {
    return { data: response.data, status: 200 }
  }).catch((error) => {
    return {
      data: error.data,
      status: error.status,
    }
  })
}

export {
  storeListData,
}