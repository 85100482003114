import withProtected from "../../../core/hoc/withProtected"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Banner, Store } from "../../../types"
import { uploadImage } from "../../../data/stores.data"
import {
  deleteBanner,
  editBanner,
  getSingleBanner,
} from "../../../data/banner_data"
import { CenteredContainer } from "../../../core/layouts"
import {
  CheckIcon,
  ExclamationCircleIcon,
  PencilAltIcon,
  SaveIcon,
  SelectorIcon,
} from "@heroicons/react/outline"
import { Combobox } from "@headlessui/react"
import PrimaryButton from "../../../core/components/Button"
import ThumbnailDropZone from "../../press/components/ThumbnailDropZone"
import { useNavigate, useParams } from "react-router-dom"
import toast from "react-hot-toast"
import classNames from "classnames"
import BannerView from "../components/Preview/BannerView"
import StoreDropDown from "../../../core/components/DropDown/StoreDropDown"

interface FieldValues {
  title: string
  description: string
  buttonText: string
  thumbnail: string
  mobileThumbnail: string
  TabletThumbnail: string
  url: string
  buttonTextColor: string
  buttonBackgroundColor: string
  textColor: string
}

interface ImageFile extends File {
  preview: string
  path: string
}

const textColorTypes = [
  { name: "Dark Blue", value: "#001C33" },
  { name: "White", value: "#EDF3F7" },
]

const buttonTypes = [
  {
    name: "Primary",
    buttonTextColor: "#001C33",
    buttonBackgroundColor: "#74EADA",
  },
  {
    name: "Secondary",
    buttonTextColor: "#FFFFFF",
    buttonBackgroundColor: "#001C33",
  },
]

const tabs = [
  { name: "Mobile", current: true, value: "mobileThumbnail" },
  { name: "Desktop", current: false, value: "tabletThumbnail" },
]

const EditBanner = () => {
  const [loading, setLoading] = useState(false)
  const [mobileThumbnailEditState, setMobileThumbnailEditState] =
    useState(false)
  const [tabletThumbnailEditState, setTabletThumbnailEditState] =
    useState(false)

  const [mobileThumbnail, setMobileThumbnail] = useState<ImageFile | null>(null)
  const [tabletThumbnail, setTabletThumbnail] = useState<ImageFile | null>(null)
  const [selectedStore, setSelectedStore] = useState<Store | null>(null)
  const [isStoresPopulated, setIsStoresPopulated] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch,
  } = useForm<FieldValues>()

  const navigate = useNavigate()
  const params = useParams()

  const [slug, setSlug] = useState("")
  useEffect(() => {
    const { slug } = params

    if (slug) {
      fetchBanner(slug)
    }
  }, [params])

  const fetchBanner = async (slug: string) => {
    if (slug) {
      getSingleBanner(slug).then((res) => {
        const banner: Banner | undefined = res?.data

        if (banner) {
          setSlug(banner.slug)
          setValue("title", banner.title)
          setValue("description", banner.description)
          setValue("buttonText", banner.buttonText)
          setValue("url", banner.url)
          setValue("buttonTextColor", banner.buttonTextColor)
          setValue("buttonBackgroundColor", banner.buttonBackgroundColor)
          setValue("textColor", banner.textColor)
          setMobileThumbnail({
            preview: `${process.env.REACT_APP_STORES_ASSETS}/${banner.mobileThumbnail}`,
            path: banner.mobileThumbnail,
            name: banner.mobileThumbnail.split("/")[1],
          } as ImageFile)
          setTabletThumbnail({
            preview: `${process.env.REACT_APP_STORES_ASSETS}/${banner.tabletThumbnail}`,
            path: banner.tabletThumbnail,
            name: banner.tabletThumbnail.split("/")[1],
          } as ImageFile)

          setTextColorType(
            textColorTypes.find((type) => type.value === banner?.textColor)
          )
          setButtonType(
            buttonTypes.find((type) => {
              return (
                type.buttonTextColor === banner?.buttonTextColor &&
                type.buttonBackgroundColor === banner?.buttonBackgroundColor
              )
            }) || buttonTypes[0]
          )
        }
      })
    }
  }

  function getExtension(path: string) {
    const segments = path.split(".")
    const lastIndex = segments.length - 1
    return segments[lastIndex]
  }

  async function onSubmit(data: FieldValues) {
    if (!(data.url.includes("http://") || data.url.includes("https://"))) {
      setError("url", {
        type: "manual",
        message: "URL must start with http:// or https://",
      })
      return
    }

    setLoading(true)
    try {
      const body: { [key: string]: any } = {
        title: data.title,
        url: data.url,
        slug: slug,
        description: data.description,
        buttonText: data.buttonText,
        buttonTextColor: data.buttonTextColor,
        buttonBackgroundColor: data.buttonBackgroundColor,
        textColor: data.textColor,
      }

      const response = await editBanner(body)

      if (response.success) {
        toast.success(response.message)

        fetchBanner(slug)
          .then(() => {
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        toast.error(response.message ?? "Error occured")
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message ?? "Error occured")
    }
  }

  const editImage = (imageFile: ImageFile | null, view: string) => {
    if (imageFile) {
      const uploadItem = {
        key: `banner/${Date.now()}-${view}.${getExtension(imageFile.name)}`,
        file: imageFile,
      }

      try {
        const uploadRequest: Promise<void> = uploadImage(uploadItem)

        const body: { [key: string]: any } = {
          slug: slug,
        }

        if (view === "mobile") {
          body.mobileThumbnail = uploadItem.key
        }
        if (view === "tablet") {
          body.tabletThumbnail = uploadItem.key
        }

        uploadRequest
          .then(async () => {
            const response = await editBanner(body)

            if (response.success) {
              toast.success(response.message)
            } else {
              toast.error(response.message ?? "Error occured")
            }
          })
          .then(() => {
            if (slug) {
              fetchBanner(slug)
            }
          })
      } catch (e) {
        console.log(e)
      }
    } else {
      console.log("no image")
    }
  }

  const removeBanner = async () => {
    deleteBanner(slug).then((res) => {
      if (res.success) {
        toast.success(res.message)
        navigate("/banners")
      } else {
        toast.error(res.message)
      }
    })
  }

  const [buttonType, setButtonType] = useState(buttonTypes[0])
  useEffect(() => {
    setValue("buttonTextColor", buttonType.buttonTextColor)
    setValue("buttonBackgroundColor", buttonType.buttonBackgroundColor)
  }, [buttonType])

  const [textColorType, setTextColorType] = useState<any>(textColorTypes[0])
  useEffect(() => {
    setValue("textColor", textColorType.value)
  }, [textColorType])

  const titleName = watch("title")
  const descriptionContent = watch("description")
  const buttonTextContent = watch("buttonText")
  const fontTextColor = watch("textColor")

  const dummy = () => {}

  const [tabList, setTabList] = useState(tabs)
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  useEffect(() => {
    setTabList(tabs)
    setSelectedTab(tabs[0])
  }, [slug])

  const changeSelectedTabHandler = (value: string) => {
    setTabList(
      tabs.map((tab) => {
        if (tab.name === value) {
          tab.current = true
          setSelectedTab(tab)
        } else {
          tab.current = false
        }
        return tab
      })
    )
  }

  const MOBILE = 320

  const MIN_TITLE_LENGTH = 47
  const MAX_TITLE_LENGTH = 56
  const MAX_DESCRIPTION_LENGTH = 100
  const MAX_BUTTON_TEXT_LENGTH = 20

  const [backgroundImage, setBackgroundImage] = useState<string>("")

  useEffect(() => {
    if (selectedTab.value) {
      if (selectedTab.value === "mobileThumbnail") {
        setBackgroundImage("none")
      }
      if (selectedTab.value === "tabletThumbnail") {
        setBackgroundImage(`url("${tabletThumbnail?.preview}")`)
      }
    }
  }, [selectedTab.value])

  return (
    <CenteredContainer
      title="Edit Banner"
      subtitle="You can Edit the banner here"
      paths={[
        {
          name: "Banner",
          route: "/banners",
          asLink: true,
        },
        {
          name: "Edit",
          route: "/banners/edit",
          asLink: false,
        },
      ]}
    >
      <div className="flex flex-col mt-8">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                {mobileThumbnail?.preview && backgroundImage.length > 0 && (
                  <div className={`mx-auto flex flex-col items-center`}>
                    <BannerView
                      buttonBackgroundColor={buttonType.buttonBackgroundColor}
                      buttonText={buttonTextContent}
                      buttonTextColor={buttonType.buttonTextColor}
                      description={descriptionContent}
                      desktopThumbnail={backgroundImage}
                      mobileThumbnail={mobileThumbnail.preview}
                      selectedView={selectedTab.value}
                      textColor={fontTextColor}
                      title={titleName}
                    />
                    <div className={"pt-3 w-full"}>
                      <div className={`w-full`}>
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                          <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            // defaultValue={tabs?.find((tab) => tab.current).name}
                            value={selectedTab.name}
                            onChange={(e) =>
                              changeSelectedTabHandler(e.target.value)
                            }
                          >
                            {tabList.map((tab) => (
                              <option key={tab.name}>{tab.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <nav
                            className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                            aria-label="Tabs"
                          >
                            {tabList.map((tab, tabIdx) => (
                              <a
                                key={tab.name}
                                className={classNames(
                                  tab.current
                                    ? "text-gray-900"
                                    : "text-gray-500 hover:text-gray-700",
                                  tabIdx === 0 ? "rounded-l-lg" : "",
                                  tabIdx === tabs.length - 1
                                    ? "rounded-r-lg"
                                    : "",
                                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
                                )}
                                onClick={(_e) => {
                                  changeSelectedTabHandler(tab.name)
                                }}
                                aria-current={tab.current ? "page" : undefined}
                              >
                                <span>{tab.name}</span>
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    tab.current
                                      ? "bg-primary-teal"
                                      : "bg-transparent",
                                    "absolute inset-x-0 bottom-0 h-0.5"
                                  )}
                                />
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Mobile Thumbnail
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="">
                      {mobileThumbnail?.preview ? (
                        <div
                          className={`w-[300px] m-4 rounded-md h-auto flex flex-row relative`}
                        >
                          <img
                            className="rounded-md"
                            src={mobileThumbnail?.preview}
                            alt={""}
                          />
                          <div className={`flex px-4 items-center`}>
                            {!mobileThumbnailEditState ? (
                              <button
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                  setMobileThumbnail(null)
                                  setMobileThumbnailEditState(true)
                                }}
                              >
                                <PencilAltIcon className="w-5 h-5 " />
                              </button>
                            ) : (
                              <div className={`flex flex-row`}>
                                <button
                                  type="button"
                                  className="inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                  onClick={() => {
                                    setMobileThumbnail(null)
                                    setMobileThumbnailEditState(true)
                                  }}
                                >
                                  <PencilAltIcon className="w-5 h-5 " />
                                </button>
                                <button
                                  type="button"
                                  className="ml-4 inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                  onClick={() => {
                                    editImage(mobileThumbnail, "mobile")
                                    setMobileThumbnailEditState(false)
                                  }}
                                >
                                  <SaveIcon className="w-5 h-5 " />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className={`flex flex-row`}>
                          <ThumbnailDropZone
                            width={MOBILE * 2}
                            height={MOBILE * 2 * 0.6}
                            onDrop={(file) => {
                              setMobileThumbnail(file)
                            }}
                          />
                          <div className={`flex px-4 items-center`}></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Desktop Thumbnail
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="">
                      {tabletThumbnail?.preview ? (
                        <div
                          className={`w-[300px] m-4 rounded-md h-auto flex flex-row relative`}
                        >
                          <img
                            className="rounded-md"
                            src={tabletThumbnail?.preview}
                            alt={""}
                          />
                          <div className={`flex px-4 items-center`}>
                            {!tabletThumbnailEditState ? (
                              <button
                                type="button"
                                className="inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                onClick={() => {
                                  setTabletThumbnail(null)
                                  setTabletThumbnailEditState(true)
                                }}
                              >
                                <PencilAltIcon className="w-5 h-5 " />
                              </button>
                            ) : (
                              <div className={`flex flex-row`}>
                                <button
                                  type="button"
                                  className="inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                  onClick={() => {
                                    setTabletThumbnail(null)
                                    setTabletThumbnailEditState(true)
                                  }}
                                >
                                  <PencilAltIcon className="w-5 h-5 " />
                                </button>
                                <button
                                  type="button"
                                  className="ml-4 inline-flex items-center justify-center w-8 h-8 min-w-[40px] min-h-[40px] space-x-2 text-sm font-medium text-white transition-all duration-150 border border-transparent rounded-full shadow-sm bg-primary-blue hover:bg-primary-teal hover:text-primary-blue focus:outline-none focus:ring-2 focus:ring-primary-teal focus:ring-offset-2 sm:w-auto"
                                  onClick={() => {
                                    editImage(tabletThumbnail, "tablet")
                                    setTabletThumbnailEditState(false)
                                  }}
                                >
                                  <SaveIcon className="w-5 h-5 " />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className={`flex flex-row`}>
                          <ThumbnailDropZone
                            width={2400}
                            height={2400 / 3}
                            onDrop={(file) => {
                              setTabletThumbnail(file)
                            }}
                          />
                          <div className={`flex px-4 items-center`}></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Title
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("title", {
                          required: "Title cannot be empty",
                          maxLength: MAX_TITLE_LENGTH,
                          minLength: MIN_TITLE_LENGTH,
                        })}
                        className={classNames(
                          errors && errors.title
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.title && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    {errors && errors.title && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.title.message}
                      </div>
                    )}

                    {errors && errors.title && (
                      <>
                        <div className="mt-2 text-sm text-red-600">
                          {errors.title.type === "minLength" &&
                            `Title cannot be shorter than ${MIN_TITLE_LENGTH} characters`}
                        </div>
                        <div className="mt-2 text-sm text-red-600">
                          {errors.title.type === "maxLength" &&
                            `Title cannot be longer than ${MAX_TITLE_LENGTH} characters`}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Description
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("description", {
                          required: "Description cannot be empty",
                          maxLength: MAX_DESCRIPTION_LENGTH,
                        })}
                        className={classNames(
                          errors && errors.description
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.description && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    {errors && errors.description && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.description.message}
                      </div>
                    )}

                    {errors && errors.description && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.description.type === "maxLength" &&
                          `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="buttonText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Text Color
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md ">
                      <Combobox
                        as="div"
                        value={textColorType}
                        onChange={setTextColorType}
                      >
                        <div className="relative mt-1">
                          <Combobox.Input
                            // ref={inputRef}
                            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
                            // onChange={(event) => setQuery(event.target.value)}
                            onChange={dummy}
                            displayValue={(textColor: any) => textColor.name}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>

                          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {textColorTypes.map((colorType) => (
                              <Combobox.Option
                                key={colorType.name}
                                value={colorType}
                                className={({ active }) =>
                                  classNames(
                                    "relative cursor-default select-none py-2 pl-8 pr-4",
                                    active
                                      ? "bg-primary-blue text-primary-teal"
                                      : "text-gray-900"
                                  )
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        "block truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {colorType.name}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames(
                                          "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                          active
                                            ? "text-white"
                                            : "text-primary-blue"
                                        )}
                                      >
                                        <CheckIcon
                                          className="w-5 h-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </div>
                      </Combobox>
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="buttonText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Button Text
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("buttonText", {
                          required: "Button text cannot be empty",
                          maxLength: MAX_BUTTON_TEXT_LENGTH,
                        })}
                        className={classNames(
                          errors && errors.buttonText
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.buttonText && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    {errors && errors.buttonText && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.buttonText.message}
                      </div>
                    )}
                    {errors && errors.buttonText && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.buttonText.type === "maxLength" &&
                          `Button text cannot be more than ${MAX_BUTTON_TEXT_LENGTH} characters`}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="buttonText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Button Type
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md ">
                      <Combobox
                        as="div"
                        value={buttonType}
                        onChange={setButtonType}
                      >
                        <div className="relative mt-1">
                          <Combobox.Input
                            // ref={inputRef}
                            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-prborder-primary-blue sm:text-sm"
                            // onChange={(event) => setQuery(event.target.value)}
                            onChange={dummy}
                            displayValue={(buttonType: any) => buttonType.name}
                          />
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                            <SelectorIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>

                          <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {buttonTypes.map((buttonType) => (
                              <Combobox.Option
                                key={buttonType.name}
                                value={buttonType}
                                className={({ active }) =>
                                  classNames(
                                    "relative cursor-default select-none py-2 pl-8 pr-4",
                                    active
                                      ? "bg-primary-blue text-primary-teal"
                                      : "text-gray-900"
                                  )
                                }
                              >
                                {({ active, selected }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        "block truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {buttonType.name}
                                    </span>

                                    {selected && (
                                      <span
                                        className={classNames(
                                          "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                          active
                                            ? "text-white"
                                            : "text-primary-blue"
                                        )}
                                      >
                                        <CheckIcon
                                          className="w-5 h-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        </div>
                      </Combobox>
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="buttonText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Redirect URL
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="relative flex max-w-lg rounded-md shadow-sm">
                      <input
                        disabled={loading}
                        type="text"
                        {...register("url", {
                          required: "Redirection URL cannot be empty",
                        })}
                        className={classNames(
                          errors && errors.url
                            ? "border-red-300 text-red-900  focus:outline-none focus:ring-red-500 focus:border-red-500"
                            : "focus:ring-primary-teal focus:border-primary-teal",
                          "flex-1 block w-full pr-12 border-gray-300 rounded-md shadow-sm  sm:text-sm"
                        )}
                      />
                      {errors && errors.url && (
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>

                    {errors && errors.url && (
                      <div className="mt-2 text-sm text-red-600">
                        {errors.url.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="buttonText"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Store To Bind (for Analytics)
                  </label>
                  <div className="w-3/5">
                    <StoreDropDown
                      withEmptyOption={true}
                      selectedStore={selectedStore}
                      setSelectedStore={setSelectedStore}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5 pb-14">
            <div className={`flex justify-between`}>
              <div>
                <PrimaryButton
                  onClick={removeBanner}
                  disabled={loading}
                  loading={loading}
                  className={`bg-primary-red hover:bg-red-600 hover:text-red-100 focus:ring-red-600`}
                  type="button"
                  title={"Delete"}
                ></PrimaryButton>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => {
                    navigate("/banners")
                  }}
                  disabled={loading}
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-teal"
                >
                  Cancel
                </button>
                <PrimaryButton
                  disabled={loading}
                  loading={loading}
                  type="submit"
                  title={"Save Changes"}
                ></PrimaryButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </CenteredContainer>
  )
}

export default withProtected(EditBanner)
