import { FC, useEffect, useState } from "react"
import { Category, Store } from "../../../types"
import { Link } from "react-router-dom"
import { getCategoriesByStore } from "../../../data/categories.data"
import Skeleton from "react-loading-skeleton"
import { useAppDispatch, useAppSelector } from "../../../features/hooks/redux"
import { setStoreItemData } from "../../../features/storeSlice"

interface StoreListItemProps {
  store: Store;
}

const StoreListItem: FC<StoreListItemProps> = ({ store }) => {
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [categories, setCategories] = useState<Category[]>([])

  const storedStoreItem = useAppSelector((state) => state.storeItem.storeItemData)
  const dispatch = useAppDispatch()

  const getCategoriesByStoreFromApi = async () => {
    const isItemInList = storedStoreItem.some((item) => item.store.slug === store.slug)
    const storedItem = storedStoreItem.find((item) => item.store.slug === store.slug)

    if (isItemInList) {
      if (storedItem) {
        setCategories(storedItem.categories)
      }
      setLoadingCategories(false)
    } else {
      const categories = await getCategoriesByStore(store.slug)
      if (categories) {
        dispatch(setStoreItemData({
          store: store,
          categories: categories,
        }))
        setCategories(categories)
      } else {
        dispatch(setStoreItemData({
          store: store,
          categories: [],
        }))
      }
      setLoadingCategories(false)
    }
  }

  useEffect(() => {
    getCategoriesByStoreFromApi()
  }, [store.slug])

  return (
    <tr>
      <td className="py-4 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-6">
        {!loadingCategories ? <div className="flex items-center">
            <div className="flex-shrink-0 w-10 h-10">
              <img
                className="w-10 h-10 rounded-md"
                src={`${process.env.REACT_APP_STORES_ASSETS}/${
                  store.coverWithLogo ?? store.cover
                }`}
                alt={store.alterText}
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">{store.name}</div>
            </div>
          </div> :
          <div className="flex items-center">
            <Skeleton
              width={40}
              height={40}
              className="inline-flex px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
            />
            <Skeleton
              width={40}
              className="inline-flex ml-4 px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
            />
          </div>
        }
      </td>

      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {!loadingCategories ? (
          <div className="flex flex-wrap items-center mb-2">
            {categories.map((item) => {
              return (
                <Link
                  to={{ pathname: `/categories/${item.slug}` }}
                  key={item.slug}
                  className="inline-flex px-2 mb-1 mr-1 text-xs font-medium leading-5 text-teal-700 bg-teal-100 border border-teal-300 rounded-full"
                >
                  {item.name}
                </Link>
              )
            })}
          </div>
        ) : (
          <div className="flex flex-wrap items-center mb-2 space-x-2">
            {categories.map((item) => {
              return (
                <Skeleton
                  key={item.slug}
                  width={40}
                  className="inline-flex px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
                />
              )
            })}
          </div>
        )}
      </td>

      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {!loadingCategories ? (
          store.active ? (
            <span
              className="inline-flex px-2 text-xs font-medium leading-5 text-green-800 bg-green-100 border border-green-300 rounded-full">
            Active
          </span>
          ) : (
            <span
              className="inline-flex px-2 text-xs font-medium leading-5 text-red-500 bg-red-100 border border-red-300 rounded-full">
            Inactive
          </span>
          )
        ) : (
          <Skeleton
            width={40}
            className="inline-flex px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
          />
        )}

      </td>

      <td className="relative py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
        {!loadingCategories ?
          <>
            <Link
              className="text-primary-blue hover:text-primary-blue-dark"
              to={`/stores/${store.slug}`}
            >
              View
            </Link>
            <Link
              to={`/stores/edit/${store.slug}`}
              className="text-primary-blue hover:text-primary-blue-dark"
            >
              Edit
              {/* <span className="sr-only">, {person.name}</span> */}
            </Link>
          </> :
          <div className={"flex gap-x-2 justify-end"}>
            <Skeleton
              width={40}
              className="inline-flex px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
            />
            <Skeleton
              width={40}
              className="inline-flex px-2 overflow-hidden text-xs font-medium leading-5 text-transparent rounded-full"
            />
          </div>
        }

      </td>
    </tr>
  )
}

export default StoreListItem
